const frequencia = [
    {
        descricao: "Diaria",
        id: "1"
    },    
    {
        descricao: "Semanal",
        id: "2"
    }, 
    {
        descricao: "Quinzenal",
        id: "3"
    }, 
    {
        descricao: "Mensal",
        id: "4"
    }, 
    {
        descricao: "Bimestral",
        id: "5"
    }, 
    {
        descricao: "Trimestral",
        id: "6"
    }, 
    {
        descricao: "Semestral",
        id: "7"
    }, 
    {
        descricao: "Anual",
        id: "8"
    }, 

    {
        descricao: "Personalizado",
        id: "9"
    }, 
    {
        descricao: "Personalizado 2",
        id: "10"
    }, 
    {
        descricao: "Personalizado Hora",
        id: "11"
    }, 

];

export default frequencia;