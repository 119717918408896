const tipoUser = [
  
    {
        descricao: "Administrador",
        identificador: "Administrador",
        permissao: "Administrador"
    },
    {
        descricao: "Gestor",
        identificador: "Padrao",
        permissao: "Padrao"
    },   
    {
        descricao: "Operador de Campo",
        identificador: "Operador",
        permissao: "Padrao"
    },   
];

export default tipoUser;