
import React, { useState, useEffect } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import { BiSearchAlt } from "react-icons/bi";
import { BiUser } from "react-icons/bi";
import { AiOutlineCalendar } from "react-icons/ai";
import { AxiosResponse } from "axios";
import "./index.css";
import tipoUser from "../../components/arquivo/tipoUser"
import api from '../../Services/api'
import { useNavigate } from 'react-router';
import Swal from 'sweetalert2';
import Modal, { ModalHeader, useModal, ModalBody } from '../../components/ModalConfirmacao/Modal';
import { AiOutlineInfoCircle } from "react-icons/ai"

function UsuariosAdd() {

    const navigate = useNavigate();
    const [active, setActive] = useState(true);
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [integrationid, setIntegrationid] = useState('');
    const [email, setEmail] = useState('');
    const [usertype, setUsertype] = useState('');
    const [cellphoneDDD, setCellphoneDDD] = useState('');
    const [cellphone, setCellphone] = useState('');
    const [phoneDDD, setPhoneDDD] = useState('');
    const [phone, setPhone] = useState('');
    const [observation, setObservation] = useState('');
    const [company, setCompany] = useState('');
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [cityNeighborhood, setCityNeighborhood] = useState('');
    const [street, setStreet] = useState('');
    const [streetnumber, setStreetnumber] = useState('');
    const [streetComplement, setStreetComplement] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [inclusiondate, setInclusiondate] = useState('');
    const [changedate, setChangedate] = useState('');

    const token = localStorage.getItem("userToken")
    const { isShowing, toggle } = useModal();
    const [dadosCompany, setDadosCompany] = useState([]);
    const [carregaFormaEquipe, setCarregaFormaEquipe] = useState(GetCompany);
    const [dadosPermissaoUser, setDadosPermissaoUser] = useState([]);

    api.defaults.headers.common['Authorization'] = token ? token : "";

    useEffect(() => {

       // setDadosPermissaoUser(tipoUser as [])
        
        if(localStorage.getItem("usertype") === 'Padrao'){
            const temp_rows =  tipoUser.filter(
                (e: any) => {
                    return e.permissao === 'Padrao'
                }
            );
            setDadosPermissaoUser(temp_rows as []);
        }else if(localStorage.getItem("usertype") === 'Operador'){
            const temp_rows =  tipoUser.filter(
                (e: any) => {
                    return e.permissao === 'Padrao'
                }
            );
            setDadosPermissaoUser(temp_rows as []);
        } if(localStorage.getItem("usertype") === 'Administrador'){
            setDadosPermissaoUser(tipoUser as []);
        }

        var Company = document.getElementById("AMB") as HTMLInputElement;


            Company.disabled = true;

            let today = new Date();
            let date =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1).toString().padStart(2, "0") +
            "-" +
            today.getDate().toString().padStart(2, "0");
            let time =
            today.getHours().toString().padStart(2, "0") +
            ":" +
            today.getMinutes().toString().padStart(2, "0");
            let datatime = date + "T" + time;
            setInclusiondate(datatime);
        

        
    }, []);

    const renderTipoUser = dadosPermissaoUser.map((tdata: any) => {
        return <option value={tdata.identificador} key={tdata.identificador}> {tdata.descricao} </option>;
    });

    function ValitarAtivo() {
        let e = document.getElementById("checkbox") as HTMLInputElement;
        if (e.checked) {

            setActive(true)
        } else {

            setActive(false)
        }
    }

    function Ambiente(ambiente: any) {
        if (ambiente === 'Selecione...') {
            setCompany('')
        } else {
            setCompany(ambiente)
        }
    }

    function Tipouser(tipo: any) {

        var Company = document.getElementById("AMB") as HTMLInputElement;

        if (tipo === 'Selecione...') {
            setUsertype('')
            setCompany('')
            Company.disabled = true;
        } else
            if (tipo === 'Administrador') {
                setCompany('')
                setUsertype(tipo)
                Company.disabled = true;
            } else if (tipo === 'Padrao' || tipo === 'Operador') {
                Company.disabled = false;
                setUsertype(tipo)
            }
    }



    async function GetCompany() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("ambiente/listarambiente")

            if (ret.status === 200) {
                valor = ret.data.data;
                setDadosCompany(valor);

            }
        } catch (e) { }
    }

    const renderAmbiente = dadosCompany.map((comp: any) => {
        return (
            <option key={comp.empresa} value={comp.empresa}>
                {" "}
                {comp.empresa}{" "}
            </option>
        );
    });


    async function SalvarUsuario() {

        let Stype: string;

        if (usertype === 'Administrador') {
            Stype = 'MASTER'
        } else {
            Stype = (company)
        }
        let ret: AxiosResponse;
        const dados = {
            active,
            name,
            login,
            password,
            integrationid,
            email,
            usertype,
            cellphone: cellphoneDDD + cellphone,
            phone: phoneDDD + phone,
            observation,
            company: Stype,
            country,
            state,
            city,
            cityNeighborhood,
            street,
            streetnumber,
            streetComplement,
            zipcode,
        };

        if (name === undefined || name === '') {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: 'Informe o nome.',
                confirmButtonColor: "#222d32",
            })
        }

        else if (login === undefined || login === '') {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: 'Informe o login.',
                confirmButtonColor: "#222d32",
            })
        }
        else if (password === undefined || password === '') {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: 'Informe a senha.',
                confirmButtonColor: "#222d32",
            })
        }
        else if (email === undefined || email === '') {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: 'Informe o E-mail.',
                confirmButtonColor: "#222d32",
            })
        }
        else if (usertype === undefined || usertype === '') {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: 'Informe o Tipo de usuário.',
                confirmButtonColor: "#222d32",
            })
        }
        else if (usertype === 'Padrao' && company === '') {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: 'Informe o ambiente.',
                confirmButtonColor: "#222d32",
            })
        }
        else {
            try {
                ret = await api.post("user/insert", {
                    data: dados,
                });

                if (ret.data.status === 200) {
                    setId('')
                    setName('')
                    setLogin('')
                    setPassword('')
                    setIntegrationid('')
                    setEmail('')
                    setUsertype('')
                    setCellphoneDDD('')
                    setCellphone('')
                    setPhoneDDD('')
                    setPhone('')
                    setObservation('')
                    setCompany('')
                    setCountry('')
                    setState('')
                    setCity('')
                    setCityNeighborhood('')
                    setStreet('')
                    setStreetnumber('')
                    setStreetComplement('')
                    setZipcode('')
                    setChangedate('')
                    Swal.fire({
                        icon: 'success',
                        text: 'Cadastro realizado com sucesso!',
                        confirmButtonColor: "#222d32",
                    })
                    navigate('/user')
                }
            } catch (e: any) {

                if (e.response.status === 401) {
                    Swal.fire({
                        icon: 'error',
                        title: 'ATENÇÃO',
                        text: 'Token inválido ou expirado!',
                        confirmButtonColor: "#222d32",
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'ATENÇÃO',
                        text: `Não foi possível concluir o cadastramento erro: ${e.response.status}!`,
                        confirmButtonColor: "#222d32",
                    })
                }
            }
        }
    }

    function CancelarUsuario() {
        navigate('/user')
    }

    return (
        <>
            <Nav />
            <div className="main-content">
                <Header />
                <main>
                <div className='containerPrincipal'>
                        <div className="title-Page">
                            <div className="title-informacao">
                                <div className="size-x circle">1</div>
                                <h2>Informações Gerais</h2>
                            </div>
                            <div className="linha-Form" />
                        </div>

                        <div className="divativ">
                            <input type="checkbox" id="checkbox" checked={active} onChange={ValitarAtivo} />
                            <span className="details">Ativo</span>
                        </div>


                        <div className="container-1-user">
                            <div id="content1">
                                <div className="input-box-user">
                                    <span className="details">Nome*</span>
                                    <input
                                        onChange={(e) => setName(e.target.value)}
                                        value={name}
                                    />

                                </div>
                            </div>
                            <div id="content2">
                                <div className="input-box-user">
                                    <span className="details">Login*</span>
                                    <input
                                        type="text"
                                        onChange={(e) => setLogin(e.target.value)}
                                        value={login}
                                    />
                                </div>
                            </div>
                            <div id="content3">
                                <div className="input-box-user">
                                    <span className="details">Senha*</span>
                                    <input
                                        type="password"
                                        onChange={(e) => setPassword(e.target.value)}
                                        value={password}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="container-2-user">
                            <div id="content1">
                                <div className="input-box-user">
                                    <span className="details">
                                        ID para Integração</span>
                                    <input
                                        type="text"
                                        onChange={(e) => setIntegrationid(e.target.value)}
                                        value={integrationid}
                                    />
                                </div>
                            </div>


                            <div id="content2">
                                <div className="input-box-user">
                                    <span className="details">DDD</span>
                                    <input
                                        type="text"
                                        onChange={(e) => setCellphoneDDD(e.target.value)}
                                        value={cellphoneDDD}
                                    />
                                </div>
                            </div>

                            <div id="content3">
                                <div className="input-box-user">
                                    <span className="details">Celular</span>
                                    <input
                                        type="text"
                                        onChange={(e) => setCellphone(e.target.value)}
                                        value={cellphone}
                                    />
                                </div>
                            </div>

                            <div id="content4">
                                <div className="input-box-user">
                                    <span className="details">DDD</span>
                                    <input
                                        type="text"
                                        onChange={(e) => setPhoneDDD(e.target.value)}
                                        value={phoneDDD}
                                    />
                                </div>
                            </div>

                            <div id="content5">
                                <div className="input-box-user">
                                    <span className="details">Telefone</span>
                                    <input
                                        type="text"
                                        onChange={(e) => setPhone(e.target.value)}
                                        value={phone}
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="container-3-user">

                            <div id="content1">
                                <div className="input-box-user">
                                    <span className="details">E-mail*</span>
                                    <input
                                        type="text"
                                        onChange={(e) => setEmail(e.target.value)}
                                        value={email}
                                    />
                                </div>
                            </div>

                            <div id="content2">
                                <div className="input-box-user">
                                    <span className="details">Tipo de Usuário*</span>
                                    <select
                                        id="TIPUSER"
                                        onChange={(e) => Tipouser(e.target.value)}
                                        value={usertype}
                                    >
                                        <option>Selecione...
                                        </option>
                                        {renderTipoUser}
                                    </select>
                                </div>
                            </div>
                            <div id="content3">
                                <div className="input-box-user">
                                    <span className="details">Ambiente*</span>
                                    <select id="AMB"
                                        onChange={(e) => Ambiente(e.target.value)}
                                        value={company}
                                    >
                                        <option>Selecione...
                                        </option>
                                        {renderAmbiente}
                                    </select>

                                </div>

                            </div>






                            <div id="content4">
                                <div className="input-box-user">
                                    <span className="details">Data da inclusão</span>
                                    <input
                                        type="datetime-local"
                                        id="DATA-TIME"
                                        name="DATE-TIME"
                                        onChange={(e) => setInclusiondate(e.target.value)}
                                        value={inclusiondate}
                                        disabled
                                    />
                                </div>
                            </div>

                            <div id="content5">
                                <div className="input-box-user">
                                    <span className="details">Data da última atualização</span>
                                    <input
                                        type="datetime-local"
                                        id="DATA-TIME"
                                        name="DATE-TIME"
                                        onChange={(e) => setChangedate(e.target.value)}
                                        value={changedate}
                                        disabled
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="container-4-user">
                            <div className="input-box-user">
                                <span className="details">Observação</span>
                                <textarea
                                    onChange={(e) => setObservation(e.target.value)}
                                    value={observation}
                                />
                            </div>
                        </div>

                        <div className="title-Page">
                            <div className="title-informacao">
                                <div className="size-x circle">2</div>
                                <h2>Endereço</h2>
                            </div>
                            <div className="linha-Form" />
                        </div>

                        <div className="container-5-user">

                            <div id="content1">
                                <div className="input-box-user">
                                    <span className="details">Pais</span>
                                    <input
                                        type="text"
                                        onChange={(e) => setCountry(e.target.value)}
                                        value={country}
                                    />
                                </div>
                            </div>
                            <div id="content2">
                                <div className="input-box-user">
                                    <span className="details">Estado</span>
                                    <input
                                        type="text"
                                        onChange={(e) => setState(e.target.value)}
                                        value={state}
                                    />
                                </div>
                            </div>
                            <div id="content3">
                                <div className="input-box-user">
                                    <span className="details">Município</span>
                                    <input
                                        type="text"
                                        onChange={(e) => setCity(e.target.value)}
                                        value={city}
                                    />
                                </div>
                            </div>
                            <div id="content4">
                                <div className="input-box-user">
                                    <span className="details">Bairro</span>
                                    <input
                                        type="text"
                                        onChange={(e) => setCityNeighborhood(e.target.value)}
                                        value={cityNeighborhood}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="container-6-user">
                            <div id="content1">
                                <div className="input-box-user">
                                    <span className="details">Logradouro</span>
                                    <input
                                        type="text"
                                        onChange={(e) => setStreet(e.target.value)}
                                        value={street}
                                    />
                                </div>
                            </div>
                            <div id="content2">
                                <div className="input-box-user">
                                    <span className="details">Número</span>
                                    <input
                                        type="text"
                                        onChange={(e) => setStreetnumber(e.target.value)}
                                        value={streetnumber}
                                    />
                                </div>
                            </div>
                            <div id="content3">
                                <div className="input-box-user">
                                    <span className="details">Complemento</span>
                                    <input
                                        type="text"
                                        onChange={(e) => setStreetComplement(e.target.value)}
                                        value={streetComplement}
                                    />
                                </div>
                            </div>
                            <div id="content4">
                                <div className="input-box-user">
                                    <span className="details">CEP</span>
                                    <input
                                        type="text"
                                        onChange={(e) => setZipcode(e.target.value)}
                                        value={zipcode}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="Bt-button-chamado-user">
                            <button type="submit" onClick={SalvarUsuario}>
                                <i className="fa fa-check-circle icon-ok-btn"></i> Salvar
                            </button>
                            <button type="submit" onClick={CancelarUsuario}>
                                <i className="fa fa-times icon-cancelar-btn"></i> Cancelar
                            </button>
                        </div>
                    </div>
                </main>
            </div>
            <Modal {...{ isShowing, toggle }}>
                <ModalHeader {...{ toggle }}>
                    <span className='title-modal'> E</span>

                </ModalHeader>
                <ModalBody>
                    <div className='modal-cont'>
                        <AiOutlineInfoCircle className='icon-int' />
                        <h1 className='h1-text'>Deseja excluir o registro selecionado?</h1>
                        <div className="wrapper">
                            <div className="box2">Confirmar</div>
                        </div>

                    </div>
                </ModalBody>

            </Modal>
        </>
    );

}

export default UsuariosAdd;