import React, {useState} from 'react';
import './style.css'


const Loading = () => {
    return (
        <>
        <div className='loader'></div>
        <h1 className='loader-text'>Carregando...</h1>
        </>

     )
}

export default Loading;