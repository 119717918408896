
import React from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";

function NoPage() {
    return (
        <>

      
            <div className='containerPrincipal'>
                 <h1>NoPage</h1> 
            </div>
          
        
        </>
    );

}

export default NoPage;