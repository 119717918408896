
import React, { useState, useEffect, useDebugValue } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import { AiOutlineCalendar, AiOutlineUserAdd } from "react-icons/ai";
import { GoLocation } from 'react-icons/go'
import "./index.css";
import frequenciaE from "../../components/arquivo/Frequencia";
import DualListBox from "react-dual-listbox";
import GridLocais from '../../components/GridLocais'
import PersonalizadoData from "../../components/PersonalizadoData";
import { AxiosResponse } from "axios";
import api from '../../Services/api'
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router';
import moment from 'moment';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { useParams } from 'react-router-dom';

function PlanejadorAdd(props: any) {

    let { amb } = useParams();
    const [toggleState, setToggleState] = useState(1);
    const [date, setDate] = useState('');
    const navigate = useNavigate();
    const [id, setIdAgenda] = useState('');
    const [descricao, setDescricao] = useState(''); //no banco o D está maiúsculo
    const [ativo, setAtivo] = useState(true);
    const [ambiente, setAmbiente] = useState('');
    const [pessoa, SetPessoa] = useState('');
    const [equipe, SetEquipe] = useState('');
    const [situacao, SetSituacao] = useState('');
    const [frequencia, SetFrequencia] = useState('');
    const [freq_dias, SetFreq_dias] = useState('');
    const [pular_dom, SetPular_dom] = useState(false)
    const [pular_sab, SetPular_sab] = useState(false)
    const [dataIncial, SetDataIncial] = useState('');
    const [datafim, SetDatafim] = useState('');
    const [horaIncial, SetHoraIncial] = useState('');
    const [horafim, SetHorafim] = useState('');
    const [d1, SetD1] = useState(false);
    const [d2, SetD2] = useState(false);
    const [d3, SetD3] = useState(false);
    const [d4, SetD4] = useState(false);
    const [d5, SetD5] = useState(false);
    const [d6, SetD6] = useState(false);
    const [d7, SetD7] = useState(false);
    const [proximaExec, setProximaExec] = useState('');
    const [dateproximaExec, setDateProximaExec] = useState(new Date());
    const [forma, SetForma] = useState('');
    const [tipoTarefa, SetTipoTarefa] = useState('');
    const [antecipar, SetAntecipar] = useState('');
    const [modoAtividade, SetModoAtividade] = useState('');
    const [msgDia, SetMsgDia] = useState('');
    const [notificacao, SetNotificacao] = useState(false);
    const [tts, SetTts] = useState(false);
    const [sms, SetSms] = useState(false);
    const [email, SetEMail] = useState(false);
    const [recriar, SetRecriar] = useState(false);
    const [desativar, SetDesativar] = useState(false);
    const [noti_umov, SetNoti_umov] = useState(false);
    const [observacao, SetObservacao] = useState(''); //ATENÇÃO FALTA INCLUIR
    const [subtipo, SetSubtipo] = useState(''); //ATENÇÃO FALTA INCLUIR
    const [mail, setMail] = useState('')
    const [atvSelecionada, setAtvSelecionada] = useState([]);
    const [dadosLocais, setDadosLocais] = useState([]);
    const [frequencia10, setFrequencia10] = useState('');
    const [dadosPessoas, setDadosPessoas] = useState([]);
    const [dadosEquipe, setDadosEquipe] = useState([]);
    const [dadosSituacao, setDadosSituacao] = useState([]);
    const [dadosFormaEquipe, setDadosFormaEquipe] = useState([]);
    const [dadosModoAtividade, setDadosModoAtividade] = useState([]);
    const [dadosAtividade, setDadosAtividade] = useState([]);
    const [dadosTipoTarefa, setDadosTipoTarefa] = useState([]);
    const [dadosSubtipoTarefa, setDadosSubtipoTarefa] = useState([]);

    //  const [carregaLocais, setCarregaLocais] = useState(GetSubtipoTarefa);
    const [dadosPersonalizado2, setDadosPersonalizado2] = useState('');
    const [atvSelecionadaBD, setAtvSelecionadaBD] = useState([]);
    const [dadosLocal, setDadosLocal] = useState([]);
    const [proximaExecFunc, setProximaExecFunc] = useState('');

    const [dadosAgendaUP, setDadosAgendaUP] = useState('{}');
    const [dadosLocalUP, setDadosLocalUP] = useState([]);
    const [dadosAtividadesUP, setDadosAtividadesUP] = useState([]);
    const [dadosDataAgendaUP, setDadosDataAgendaUP] = useState([]);



    useEffect(() => {

        console.log('Meu ambiente:', amb) 
        
        validarAcesso()

        let checkd1 = document.getElementById("checkbox-D1") as HTMLInputElement;
        let checkd2 = document.getElementById("checkbox-D2") as HTMLInputElement;
        let checkd3 = document.getElementById("checkbox-D3") as HTMLInputElement;
        let checkd4 = document.getElementById("checkbox-D4") as HTMLInputElement;
        let checkd5 = document.getElementById("checkbox-D5") as HTMLInputElement;
        let checkd6 = document.getElementById("checkbox-D6") as HTMLInputElement;
        let checkd7 = document.getElementById("checkbox-D7") as HTMLInputElement;
        let checkboxpularsab = document.getElementById("checkbox-pular-sab") as HTMLInputElement;
        let checkboxpulardom = document.getElementById("checkbox-pular-dom") as HTMLInputElement;


        checkboxpularsab.disabled = false;
        checkboxpulardom.disabled = false;
        checkd1.disabled = true;
        checkd2.disabled = true;
        checkd3.disabled = true;
        checkd4.disabled = true;
        checkd5.disabled = true;
        checkd6.disabled = true;
        checkd7.disabled = true;


    }, []);

    async function validarAcesso() {

        api.defaults.headers.common['Authorization'] = localStorage.getItem("userToken") || '{}';
        let ret: AxiosResponse;
        let valor: any;
        let ret2: AxiosResponse;
        let valor2: any;
        try {
            ret = await api.get("ambiente/listarambiente")
            if (ret.status === 200) {
                valor = ret.data.data;

                const temp_rows = valor.filter(
                    (e: any) => {
                        return e.empresa === amb
                    }
                );

                if (temp_rows.length > 0) {
                    try {
                        ret2 = await api.get("user/validar")

                        if (ret2.data.status === 200) {
                            if (ret2.data.data.use_usertype === "Operador" || ret2.data.data.use_usertype === "Padrao") {
                                if (ret2.data.data.use_company === amb) {

                                    GetPessoa();
                                    GetEquipe();
                                    GetSituacao();
                                    GetFormaEquipe();
                                    GetFormaModoAtividade();
                                    GetAtividade();
                                    GetTipoTarefa();
                                    GetSubtipoTarefa();
                                } else {
                                    navigate('/agenda');
                                }
                            } else {
                             
                                GetPessoa();
                                GetEquipe();
                                GetSituacao();
                                GetFormaEquipe();
                                GetFormaModoAtividade();
                                GetAtividade();
                                GetTipoTarefa();
                                GetSubtipoTarefa();
                            }
                        }
                    } catch (e) {
                        navigate('/agenda');
                    }
                } else {
                    navigate('/agenda');
                }
            }
        } catch (e) {
            navigate('/agenda');
        }


    }



    async function GetLocaisAgenda(id: any, ambiente: any) {
        let i = 0
        let ret: AxiosResponse;

        api.defaults.headers.common['Authorization'] = localStorage.getItem("userToken") || '{}';
        try {
            ret = await api.get("locais/listarlocais", {
                params: {
                    id: id,
                    ambiente: ambiente
                }
            })
            if (ret.data.status === 200) {

                const temp_rows = ret.data.data.filter(
                    (e: any) => {
                        return e.ok === 1
                    }
                );
                setDadosLocalUP(temp_rows);

            }
        } catch (e: any) {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: `Não foi possível concluir o cadastramento erro: ${e.response.data.message}!`,
                confirmButtonColor: "#222d32",
            })


        }
    }

    async function GetPersonalizado2(idAgenda: any) {

        localStorage.setItem("dadosPersonalizado2", JSON.stringify([]));
        api.defaults.headers.common['Authorization'] = localStorage.getItem("userToken") || '{}';
        let i = 0
        let newArray = []
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("dataagenda/listar", {
                params: {
                    id: idAgenda
                }
            })
            if (ret.data.status === 200) {

                setDadosDataAgendaUP(ret.data.data);

                for (i = 0; i < ret.data.data.length; i++) {

                    let valor = (ret.data.data[i].data?.substring(0, 10) + ' ' + ret.data.data[i].data?.substring(11, 16))

                    newArray.push(valor)
                }
                setFrequencia10('S')
                localStorage.setItem("dadosPersonalizado2", JSON.stringify(newArray));
            }
        } catch (e: any) {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: `Não foi possível concluir o cadastramento erro: ${e.response.data.message}!`,
                confirmButtonColor: "#222d32",
            })

        }
    }


    async function GetAtividadeAgenda() {
        let i = 0
        let newArray = []
        let ret: AxiosResponse;
        let valor: any;
        api.defaults.headers.common['Authorization'] = localStorage.getItem("userToken") || '{}';
        try {
            ret = await api.get("atividadeagenda/listar", {
                params: {
                    id: id
                }
            })
            if (ret.data.status === 200) {

                const newarray = ret.data.data.map((element: any) => {
                    return element.idAtividade
                })
                setAtvSelecionada(newarray)
                setDadosAtividadesUP(ret.data.data);
            }
        } catch (e: any) {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: `Não foi possível concluir o cadastramento erro: ${e.response.data.message}!`,
                confirmButtonColor: "#222d32",
            })

        }
    }

    let options = dadosAtividade.map((element: any) => {
        return { value: element.id, label: element.descricao }
    });

    async function GetPessoa() {
        let ret: AxiosResponse;
        let valor: any;
        api.defaults.headers.common['Authorization'] = localStorage.getItem("userToken") || '{}';
        try {
            ret = await api.get("/pessoa/listarpessoa", {
                params: {
                    ambiente: amb
                }
            })
            if (ret.data.status === 200) {
                valor = ret.data.data;
                setDadosPessoas(valor);
            }
        } catch (e: any) {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: `Não foi possível concluir o cadastramento erro: ${e.response.data.message}!`,
                confirmButtonColor: "#222d32",
            })
        }
    }

    const renderPessoa = dadosPessoas.map((comp: any) => {
        return (
            <option key={comp.id} value={comp.id}>
                {" "}
                {comp.nome}{" "}
            </option>
        );
    });

    async function GetEquipe() {
        let ret: AxiosResponse;
        let valor: any;
        api.defaults.headers.common['Authorization'] = localStorage.getItem("userToken") || '{}';
        try {
            ret = await api.get("/equipe/listarequipe", {
                params: {
                    ambiente: amb
                }
            })
            if (ret.data.status === 200) {
                valor = ret.data.data;
                setDadosEquipe(valor);

            }
        } catch (e: any) {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: `Não foi possível concluir o cadastramento erro: ${e.response.data.message}!`,
                confirmButtonColor: "#222d32",
            })
        }
    }

    const renderEquipe = dadosEquipe.map((comp: any) => {
        return (
            <option key={comp.id} value={comp.id}>
                {" "}
                {comp.nome}{" "}
            </option>
        );
    });



    async function GetSituacao() {
        let ret: AxiosResponse;
        let valor: any;
        api.defaults.headers.common['Authorization'] = localStorage.getItem("userToken") || '{}';
        try {
            ret = await api.get("/situacao/listarsituacao", {
                params: {
                    ambiente: amb
                }
            })
            if (ret.data.status === 200) {
                valor = ret.data.data;
                setDadosSituacao(valor);
            }
        } catch (e: any) {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: `Não foi possível concluir o cadastramento erro: ${e.response.data.message}!`,
                confirmButtonColor: "#222d32",
            })
        }
    }

    const renderSituacao = dadosSituacao.map((comp: any) => {
        return (
            <option key={comp.codigo} value={comp.codigo}>
                {" "}
                {comp.nome}{" "}
            </option>
        );
    });

    async function GetFormaEquipe() {
        let ret: AxiosResponse;
        let valor: any;
        api.defaults.headers.common['Authorization'] = localStorage.getItem("userToken") || '{}';
        try {
            ret = await api.get("forma/listarforma")

            if (ret.data.status === 200) {
                valor = ret.data.data;
                setDadosFormaEquipe(valor);

            }
        } catch (e: any) {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: `Não foi possível concluir o cadastramento erro: ${e.response.data.message}!`,
                confirmButtonColor: "#222d32",
            })
        }
    }

    const renderFormaEquipe = dadosFormaEquipe.map((comp: any) => {
        return (
            <option key={comp.id} value={comp.id}>
                {" "}
                {comp.descricao}{" "}
            </option>
        );
    });

    async function GetFormaModoAtividade() {
        let ret: AxiosResponse;
        let valor: any;
        api.defaults.headers.common['Authorization'] = localStorage.getItem("userToken") || '{}';
        try {
            ret = await api.get("/formatv/listarformatv")

            if (ret.data.status === 200) {
                valor = ret.data.data;
                setDadosModoAtividade(valor);

            }
        } catch (e: any) {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: `Não foi possível concluir o cadastramento erro: ${e.response.data.message}!`,
                confirmButtonColor: "#222d32",
            })
        }
    }

    const renderModoAtividade = dadosModoAtividade.map((comp: any) => {
        return (
            <option key={comp.id} value={comp.id}>
                {" "}
                {comp.descricao}{" "}
            </option>
        );
    });


    async function GetTipoTarefa() {
        let ret: AxiosResponse;
        let valor: any;
        api.defaults.headers.common['Authorization'] = localStorage.getItem("userToken") || '{}';
        try {
            ret = await api.get("/tipotarefa/listartipotarefas", {
                params: {
                    ambiente: amb
                }
            })

            if (ret.data.status === 200) {
                valor = ret.data.data;
                setDadosTipoTarefa(valor);

            }
        } catch (e: any) {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: `Não foi possível concluir o cadastramento erro: ${e.response.data.message}!`,
                confirmButtonColor: "#222d32",
            })
        }
    }

    const renderTipoTarefa = dadosTipoTarefa.map((comp: any) => {
        return (
            <option key={comp.id} value={comp.id}>
                {" "}
                {comp.descricao}{" "}
            </option>
        );
    });


    async function GetSubtipoTarefa() {

        api.defaults.headers.common['Authorization'] = localStorage.getItem("userToken") || '{}';
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("/subtipotarefa/listarsubtipotarefa", {
                params: {
                    ambiente: amb
                }
            })

            if (ret.data.status === 200) {
                valor = ret.data.data;

                setDadosSubtipoTarefa(valor);

            }
        } catch (e: any) {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: `Não foi possível concluir o cadastramento erro: ${e.response.data.message}!`,
                confirmButtonColor: "#222d32",
            })
        }
    }

    const renderSubtipoTarefa = dadosSubtipoTarefa.map((comp: any) => {
        return (
            <option key={comp.id} value={comp.id}>
                {" "}
                {comp.descricao}{" "}
            </option>
        );
    });




    async function GetAtividade() {
        let ret: AxiosResponse;
        let valor: any;
        api.defaults.headers.common['Authorization'] = localStorage.getItem("userToken") || '{}';
        try {
            ret = await api.get("/atividade/listaratividade", {
                params: {
                    ambiente: amb
                }
            })
            if (ret.data.status === 200) {
                valor = ret.data.data;
                setDadosAtividade(valor);

            }
        } catch (e: any) {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: `Não foi possível concluir o cadastramento erro: ${e.response.data.message}!`,
                confirmButtonColor: "#222d32",
            })
        }
    }


    function ValitarAtivo() {
        let e = document.getElementById("checkbox-ativo") as HTMLInputElement;
        if (e.checked) {

            setAtivo(true)
        } else {

            setAtivo(false)
        }
    }


    function ValitarRecriar() {
        let e = document.getElementById("checkbox-recriar") as HTMLInputElement;
        if (e.checked) {

            SetRecriar(true)
        } else {

            SetRecriar(false)
        }
    }

    function ValitarDesativar() {
        let e = document.getElementById("checkbox-desativar") as HTMLInputElement;
        if (e.checked) {

            SetDesativar(true)
        } else {

            SetDesativar(false)
        }



    }

    function ValitarD1() {


        let dataI = document.getElementById("DATAINI") as HTMLInputElement;
        let timeI = document.getElementById("TIMEINI") as HTMLInputElement;

        const dt = new Date(dataI.value);

        const prox = 6;
        const atual = dt.getDay();

        if (prox <= atual) {
            showDate(dataI.value, { ...daysMonthsYears, days: Number((7 - atual) + prox), months: Number(0), years: Number(0) });
        } else {
            showDate(dataI.value, { ...daysMonthsYears, days: Number((prox - atual)), months: Number(0), years: Number(0) });
        }


    }

    function ValitarD2() {
        let e = document.getElementById("checkbox-D2") as HTMLInputElement;
        if (e.checked) {
            SetD2(true)
        } else {
            SetD2(false)
        }
    }

    function ValitarD3() {
        let e = document.getElementById("checkbox-D3") as HTMLInputElement;
        if (e.checked) {

            SetD3(true)
        } else {

            SetD3(false)
        }
    }

    function ValitarD4() {
        let e = document.getElementById("checkbox-D4") as HTMLInputElement;
        if (e.checked) {

            SetD4(true)
        } else {

            SetD4(false)
        }
    }

    function ValitarD5() {
        let e = document.getElementById("checkbox-D5") as HTMLInputElement;
        if (e.checked) {

            SetD5(true)
        } else {

            SetD5(false)
        }
    }

    function ValitarD6() {
        let e = document.getElementById("checkbox-D6") as HTMLInputElement;
        if (e.checked) {

            SetD6(true)
        } else {

            SetD6(false)
        }
    }

    function ValitarD7() {
        let e = document.getElementById("checkbox-D7") as HTMLInputElement;
        if (e.checked) {

            SetD7(true)
        } else {

            SetD7(false)
        }
    }

    function ValidarPular_sab() {
        let e = document.getElementById("checkbox-pular-sab") as HTMLInputElement;
        if (e.checked) {

            SetPular_sab(true)
        } else {

            SetPular_sab(false)
        }
    }

    function ValidarPular_dom() {
        let e = document.getElementById("checkbox-pular-dom") as HTMLInputElement;
        if (e.checked) {

            SetPular_dom(true)
        } else {

            SetPular_dom(false)
        }
    }

    function ValitarNotificacao() {
        let e = document.getElementById("checkbox-notificacao") as HTMLInputElement;
        if (e.checked) {

            SetNotificacao(true)

        } else {

            SetNotificacao(false)

        }
    }

    function ValitarTTS() {
        let e = document.getElementById("checkbox-tts") as HTMLInputElement;
        if (e.checked) {

            SetTts(true)

        } else {

            SetTts(false)

        }
    }

    function ValitarSMS() {
        let e = document.getElementById("checkbox-sms") as HTMLInputElement;
        if (e.checked) {

            SetSms(true)

        } else {

            SetSms(false)

        }
    }

    function ValitarEMail() {
        let e = document.getElementById("checkbox-email") as HTMLInputElement;
        if (e.checked) {

            SetEMail(true)

        } else {

            SetEMail(false)

        }
    }

    function ValitarNoti_umov() {
        let e = document.getElementById("checkbox-noti_umov") as HTMLInputElement;
        if (e.checked) {

            SetNoti_umov(true)

        } else {

            SetNoti_umov(false)

        }
    }


    const [daysMonthsYears, setDaysMonthsYears] = useState({
        days: 0,
        months: 0,
        years: 0,
    });

    const renderFrequencia = frequenciaE.map((tdata: any) => {
        return <option value={tdata.id} key={tdata.id}> {tdata.descricao} </option>;
    });

    function showDate(dateString: any, daysMonthsYears: any) {
        const dateToShow = new Date(dateString);

        let timeI = document.getElementById("TIMEINI") as HTMLInputElement;

        if (!dateString || !dateToShow.getTime()) return '';

        dateToShow.setUTCHours(3);

        const { days, months, years } = daysMonthsYears;

        if (days) dateToShow.setDate(dateToShow.getDate() + Number(days));
        if (months) dateToShow.setMonth(dateToShow.getMonth() + Number(months));
        if (years) dateToShow.setFullYear(dateToShow.getFullYear() + Number(years));
        return setProximaExec(dateToShow.toLocaleDateString() + ' ' + timeI.value), setDateProximaExec(dateToShow)

    }

    function Frequencia() {
        let datain = document.getElementById("DATAINI") as HTMLInputElement;
        setDate(datain.value);

        let freq = document.getElementById("FREQUEC") as HTMLInputElement;

        let PROXE = document.getElementById("PROX") as HTMLInputElement;
        let dataI = document.getElementById("DATAINI") as HTMLInputElement;
        let dataF = document.getElementById("DATAFIM") as HTMLInputElement;
        let timeI = document.getElementById("TIMEINI") as HTMLInputElement;
        let timeF = document.getElementById("TIMEFIM") as HTMLInputElement;

        let checkd1 = document.getElementById("checkbox-D1") as HTMLInputElement;
        let checkd2 = document.getElementById("checkbox-D2") as HTMLInputElement;
        let checkd3 = document.getElementById("checkbox-D3") as HTMLInputElement;
        let checkd4 = document.getElementById("checkbox-D4") as HTMLInputElement;
        let checkd5 = document.getElementById("checkbox-D5") as HTMLInputElement;
        let checkd6 = document.getElementById("checkbox-D6") as HTMLInputElement;
        let checkd7 = document.getElementById("checkbox-D7") as HTMLInputElement;
        let checkboxpularsab = document.getElementById("checkbox-pular-sab") as HTMLInputElement;
        let checkboxpulardom = document.getElementById("checkbox-pular-dom") as HTMLInputElement;

        let vfreqdias = document.getElementById("validar-freqdias") as HTMLInputElement;
        SetDataIncial(dataI.value)
        SetHoraIncial(timeI.value)
        SetHorafim(timeF.value)
        SetFrequencia(freq.value)
        setProximaExecFunc('OK')

        if (freq.value === '1') {
            checkd1.disabled = true;
            checkd2.disabled = true;
            checkd3.disabled = true;
            checkd4.disabled = true;
            checkd5.disabled = true;
            checkd6.disabled = true;
            checkd7.disabled = true;
            SetD1(false)
            SetD2(false)
            SetD3(false)
            SetD4(false)
            SetD5(false)
            SetD6(false)
            SetD7(false)
            checkboxpularsab.disabled = false;
            checkboxpulardom.disabled = false;
            showDate(datain.value, { ...daysMonthsYears, days: Number(1), months: Number(0), years: Number(0) });

            SetFreq_dias("")
            setFrequencia10('N')
        } else if (freq.value === '2') {
            SetPular_sab(false)
            SetPular_dom(false)
            setFrequencia10('N')
            checkd1.disabled = false;
            checkd2.disabled = false;
            checkd3.disabled = false;
            checkd4.disabled = false;
            checkd5.disabled = false;
            checkd6.disabled = false;
            checkd7.disabled = false;
            checkboxpularsab.disabled = true;
            checkboxpulardom.disabled = true;
            SetFreq_dias("")


            if (checkd1.checked) {
                SetD1(true)
            } else {

                SetD1(false)
            }
            if (checkd2.checked) {
                SetD2(true)
            } else {
                SetD2(false)
            }
            if (checkd3.checked) {
                SetD3(true)
            } else {
                SetD3(false)
            }
            if (checkd4.checked) {
                SetD4(true)
            } else {
                SetD4(false)
            }
            if (checkd5.checked) {
                SetD5(true)
            } else {
                SetD5(false)
            }
            if (checkd6.checked) {
                SetD6(true)
            } else {
                SetD6(false)
            }
            if (checkd7.checked) {
                SetD7(true)
            } else {
                SetD7(false)
            }

            const dt = new Date(dataI.value);


            const atual = dt.getDay();

            var newArray = [checkd2.checked, checkd3.checked, checkd4.checked, checkd5.checked, checkd6.checked, checkd7.checked, checkd1.checked];


            // 2
            var newArrayPt1 = newArray.slice(atual + 1)
            var newArrayPt2 = newArray.slice(0, atual + 1)



            var pos = newArrayPt1.indexOf(true) + (atual + 1);

            if (newArrayPt1.indexOf(true) === -1) {

                pos = newArrayPt2.indexOf(true);
            }

            const prox = pos;

            if (pos === -1) {
                showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(0), years: Number(0) });
            } else {

                if (prox <= atual) {

                    showDate(dataI.value, { ...daysMonthsYears, days: Number((7 - atual) + prox), months: Number(0), years: Number(0) });
                } else {

                    showDate(dataI.value, { ...daysMonthsYears, days: Number((prox - atual)), months: Number(0), years: Number(0) });
                }

            }

        } else if (freq.value === '3') {
            checkboxpularsab.disabled = false;
            checkboxpulardom.disabled = false;
            setFrequencia10('N')
            checkd1.disabled = true;
            checkd2.disabled = true;
            checkd3.disabled = true;
            checkd4.disabled = true;
            checkd5.disabled = true;
            checkd6.disabled = true;
            checkd7.disabled = true;
            SetD1(false)
            SetD2(false)
            SetD3(false)
            SetD4(false)
            SetD5(false)
            SetD6(false)
            SetD7(false)
            showDate(datain.value, { ...daysMonthsYears, days: Number(15), months: Number(0), years: Number(0) });

            SetFreq_dias("")
        } else if (freq.value === '4') {
            checkboxpularsab.disabled = false;
            checkboxpulardom.disabled = false;
            setFrequencia10('N')
            checkd1.disabled = true;
            checkd2.disabled = true;
            checkd3.disabled = true;
            checkd4.disabled = true;
            checkd5.disabled = true;
            checkd6.disabled = true;
            checkd7.disabled = true;
            SetD1(false)
            SetD2(false)
            SetD3(false)
            SetD4(false)
            SetD5(false)
            SetD6(false)
            SetD7(false)

            SetFreq_dias("")
            showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(1), years: Number(0) });
        } else if (freq.value === '5') {
            checkboxpularsab.disabled = false;
            checkboxpulardom.disabled = false;
            setFrequencia10('N')
            checkd1.disabled = true;
            checkd2.disabled = true;
            checkd3.disabled = true;
            checkd4.disabled = true;
            checkd5.disabled = true;
            checkd6.disabled = true;
            checkd7.disabled = true;
            SetD1(false)
            SetD2(false)
            SetD3(false)
            SetD4(false)
            SetD5(false)
            SetD6(false)
            SetD7(false)

            SetFreq_dias("")
            showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(2), years: Number(0) });
        } else if (freq.value === '6') {
            checkboxpularsab.disabled = false;
            checkboxpulardom.disabled = false;
            setFrequencia10('N')
            checkd1.disabled = true;
            checkd2.disabled = true;
            checkd3.disabled = true;
            checkd4.disabled = true;
            checkd5.disabled = true;
            checkd6.disabled = true;
            checkd7.disabled = true;
            SetD1(false)
            SetD2(false)
            SetD3(false)
            SetD4(false)
            SetD5(false)
            SetD6(false)
            SetD7(false)

            SetFreq_dias("")
            showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(3), years: Number(0) });
        } else if (freq.value === '7') {
            checkboxpularsab.disabled = false;
            checkboxpulardom.disabled = false;
            setFrequencia10('N')
            checkd1.disabled = true;
            checkd2.disabled = true;
            checkd3.disabled = true;
            checkd4.disabled = true;
            checkd5.disabled = true;
            checkd6.disabled = true;
            checkd7.disabled = true;
            SetD1(false)
            SetD2(false)
            SetD3(false)
            SetD4(false)
            SetD5(false)
            SetD6(false)
            SetD7(false)

            SetFreq_dias("")
            showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(6), years: Number(0) });
        } else if (freq.value === '8') {
            checkboxpularsab.disabled = false;
            checkboxpulardom.disabled = false;
            setFrequencia10('N')
            checkd1.disabled = true;
            checkd2.disabled = true;
            checkd3.disabled = true;
            checkd4.disabled = true;
            checkd5.disabled = true;
            checkd6.disabled = true;
            checkd7.disabled = true;
            SetD1(false)
            SetD2(false)
            SetD3(false)
            SetD4(false)
            SetD5(false)
            SetD6(false)
            SetD7(false)

            SetFreq_dias("")
            showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(0), years: Number(1) });
        } else if (freq.value === '9') {
            checkboxpularsab.disabled = false;
            checkboxpulardom.disabled = false;
            checkd1.disabled = true;
            checkd2.disabled = true;
            checkd3.disabled = true;
            checkd4.disabled = true;
            checkd5.disabled = true;
            checkd6.disabled = true;
            checkd7.disabled = true;
            SetD1(false)
            SetD2(false)
            SetD3(false)
            SetD4(false)
            SetD5(false)
            SetD6(false)
            SetD7(false)
            setFrequencia10('N')
            SetFreq_dias(vfreqdias.value)

            showDate(datain.value, { ...daysMonthsYears, days: Number(vfreqdias.value), months: Number(0), years: Number(0) });

        } else if (freq.value === '10') {
            checkboxpularsab.disabled = false;
            checkboxpulardom.disabled = false;
            checkd1.disabled = true;
            checkd2.disabled = true;
            checkd3.disabled = true;
            checkd4.disabled = true;
            checkd5.disabled = true;
            checkd6.disabled = true;
            checkd7.disabled = true;
            SetD1(false)
            SetD2(false)
            SetD3(false)
            SetD4(false)
            SetD5(false)
            SetD6(false)
            SetD7(false)
            setFrequencia10('S')

            SetFreq_dias("")
            localStorage.setItem("dadosPersonalizado2", JSON.stringify([]));
            showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(0), years: Number(0) });
        }
        else if (freq.value === '11') {
            checkboxpularsab.disabled = false;
            checkboxpulardom.disabled = false;
            // showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(0), years: Number(0) });
            checkd1.disabled = true;
            checkd2.disabled = true;
            checkd3.disabled = true;
            checkd4.disabled = true;
            checkd5.disabled = true;
            checkd6.disabled = true;
            checkd7.disabled = true;
            SetD1(false)
            SetD2(false)
            SetD3(false)
            SetD4(false)
            SetD5(false)
            SetD6(false)
            SetD7(false)
            setFrequencia10('N')
            showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(0), years: Number(0) });
            SetFreq_dias(vfreqdias.value)


            if (vfreqdias.value === '' && datain.value === "") {

            } else {


                let status = moment(datain.value + ' ' + timeI.value).add(Number(vfreqdias.value), 'hour').format('DD/MM/YYYY HH:mm:ss');
                setProximaExec(status)

            }

        }

    }

    function valor(selected: any) {
        if (modoAtividade === '') {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: 'Selecione o Modo de atividade!',
                confirmButtonColor: "#222d32",
            })
            setAtvSelecionada([])
        } else if (modoAtividade === '4') {
            setAtvSelecionada(selected);

        } else {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: 'O modo de atividade selecionado não permite que seja adicionado atividades!',
                confirmButtonColor: "#222d32",
            })
            setAtvSelecionada([])
        }

    }

    function PessoaAjuste(pessoa: any) {

        if (pessoa === 'Selecione...') {
            SetPessoa('')
            SetEquipe('')
            SetForma('')
        } else {
            SetPessoa(pessoa)
            SetEquipe('')
            SetForma('')
        }

    }

    function EquipeAjuste(equipe: any) {
        if (equipe === 'Selecione...') {
            SetEquipe('')
            SetPessoa('')
        } else {
            SetEquipe(equipe)
            SetPessoa('')
        }
    }

    function FormaAjuste(forma: any) {

        if (forma === 'Selecione...') {
            SetForma('')
            SetPessoa('')
        } else {
            SetForma(forma)
            SetPessoa('')
        }
    }



    function ModoAtividadeAjuste() {
        var Matv = document.getElementById("MODOATV") as HTMLInputElement;

        SetModoAtividade(Matv.value)

        if (Matv.value === 'Selecione') {
            setAtvSelecionada([])
        } else if (Matv.value === '4') {

        } else {
            setAtvSelecionada([])
        }
    }

    function personalizado2func(valor: any) {
        setDadosPersonalizado2(valor)

    }

    function carregalocal(valor: any) {
        setDadosLocal(valor)
    }

    function validaDataFim() {

        let dataF = document.getElementById("DATAFIM") as HTMLInputElement;

        SetDatafim(dataF.value)

    }

    async function SalvarTar() {
        let btnSalvarAgenda = document.getElementById("btnSalvarAgenda") as HTMLInputElement;
        api.defaults.headers.common['Authorization'] = localStorage.getItem("userToken") || '{}';
        let ret: AxiosResponse;
        let i = 0
        let newArray = []
        let newArrayDataAgenda = []
        let newArrayAtividade = []

        btnSalvarAgenda.disabled = true;

        const temp_rows = dadosLocais.filter(
            (e: any) => {
                return e.ok === 1
            }
        );

        if (descricao === '') {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: 'Informe a descrição desejada!',
                confirmButtonColor: "#222d32",
            })
            btnSalvarAgenda.disabled = false;
        }
        else if (pessoa === '' && equipe === '') {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: 'Selecione a pessoa ou equipe desejada!',
                confirmButtonColor: "#222d32",
            })
            btnSalvarAgenda.disabled = false;
        } else if (equipe !== '' && forma === '') {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: 'Selecione o modo de equipe desejada!',
                confirmButtonColor: "#222d32",
            })
            btnSalvarAgenda.disabled = false;
        } else if (situacao === '' || situacao === 'Selecione...') {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: 'Selecione a situação desejada!',
                confirmButtonColor: "#222d32",
            })
            btnSalvarAgenda.disabled = false;
        } else if (modoAtividade === '' || modoAtividade === 'Selecione...') {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: 'Selecione o modo de atividade desejada!',
                confirmButtonColor: "#222d32",
            })
            btnSalvarAgenda.disabled = false;
        } else if (modoAtividade === '4' && atvSelecionada.length === 0) {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: 'Selecione ao menos uma atividade!',
                confirmButtonColor: "#222d32",
            })
            btnSalvarAgenda.disabled = false;
        } else if (dataIncial === '') {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: 'Selecione a data inicial desejada!',
                confirmButtonColor: "#222d32",
            })
            btnSalvarAgenda.disabled = false;
        } else if (horaIncial === '') {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: 'Selecione a hora inicial desejada!',
                confirmButtonColor: "#222d32",
            })
            btnSalvarAgenda.disabled = false;
        } else if (datafim !== '' && horafim === '') {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: 'Selecione a hora final desejada!',
                confirmButtonColor: "#222d32",
            })
            btnSalvarAgenda.disabled = false;
        } else if (horafim !== '' && datafim === '') {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: 'Selecione a data final desejada!',
                confirmButtonColor: "#222d32",
            })
            btnSalvarAgenda.disabled = false;
        } else if (datafim !== '' && datafim < dataIncial) {

            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: 'Data final não pode ser menor que data incial!',
                confirmButtonColor: "#222d32",
            })
            btnSalvarAgenda.disabled = false;
        }
        else if (frequencia === '' || frequencia === 'Selecione...') {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: 'Selecione a frequência desejada!',
                confirmButtonColor: "#222d32",
            })
            btnSalvarAgenda.disabled = false;
        } else if (frequencia === '2' && d1 === false && d2 === false && d3 === false && d4 === false && d5 === false && d6 === false && d7 === false) {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: 'Selecione ao menos um dia!',
                confirmButtonColor: "#222d32",
            })
            btnSalvarAgenda.disabled = false;
        } else if (frequencia === '9' && freq_dias === "") {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: 'Informe a quantidade de dias!',
                confirmButtonColor: "#222d32",
            })
            btnSalvarAgenda.disabled = false;
        } else if (frequencia === '10' && dadosPersonalizado2.length === 0) {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: 'Selecione as datas e horas personalizadas!',
                confirmButtonColor: "#222d32",
            })
            btnSalvarAgenda.disabled = false;
        }

        else if (frequencia === '11' && freq_dias === "") {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: 'Informe a quantidade de horas desejada!',
                confirmButtonColor: "#222d32",
            })
            btnSalvarAgenda.disabled = false;
        }

        else if (temp_rows.length === 0) {

            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: 'Selecione ao menos um local!',
                confirmButtonColor: "#222d32",
            })
            btnSalvarAgenda.disabled = false;
        }
        else {

            let datafims = ''

            if (datafim === '') {
                datafims = ''
            } else {
                datafims = (moment(datafim).format("DD/MM/YYYY"))
            }


            if (tipoTarefa === 'Selecione...') {
                SetTipoTarefa('')
            }

            if (subtipo === 'Selecione...') {
                SetSubtipo('')
            }

            let validaPessoa = null
            let validaEquipe = null
            let validaForma = null
            let validaTipoTarefa = null
            let validasubtipo = null

            if (pessoa === '' || pessoa === 'Selecione...') {
                validaPessoa = (null)
            } else {
                validaPessoa = (pessoa)
            }


            if (equipe === '' || equipe === 'Selecione...') {
                validaEquipe = (null)
            } else {
                validaEquipe = equipe
            }


            if (forma === '' || forma === 'Selecione...') {
                validaForma = (null)
            } else {
                validaForma = forma
            }

            if (tipoTarefa === '' || tipoTarefa === 'Selecione...') {
                validaTipoTarefa = (null)
            } else {
                validaTipoTarefa = tipoTarefa
            }

            if (subtipo === '' || subtipo === 'Selecione...') {
                validasubtipo = (null)
            } else {
                validasubtipo = subtipo
            }

            let date =
                dateproximaExec.getFullYear() +
                "-" +
                (dateproximaExec.getMonth() + 1).toString().padStart(2, "0") +
                "-" +
                dateproximaExec.getDate().toString().padStart(2, "0");
            let datetimeprox = (date + "T" + horaIncial + ":00")



            const dados = {
                Descricao: descricao,
                ativo,
                ambiente: amb,
                atividade: '',
                pessoa: validaPessoa,
                equipe: validaEquipe,
                situacao,
                frequencia,
                freq_dias,
                pular_dom,
                pular_sab,
                datainicio: moment(dataIncial).format("DD/MM/YYYY"),
                datafim: datafims,
                horainicio: horaIncial,
                horafim,
                d1,
                d2,
                d3,
                d4,
                d5,
                d6,
                d7,
                proximaExec: datetimeprox,
                forma: validaForma,
                tipoTarefa: validaTipoTarefa,
                antecipar,
                modoAtividade,
                msgDia,
                notificacao,
                tts,
                sms,
                mail: email,
                recriar,
                desativar,
                noti_umov,
                subtipo: validasubtipo,
                observacao,
                email: mail
            }

            //-----------------------------------ATIVIDADE----------------------

            for (i = 0; i < atvSelecionada.length; i++) {
                newArrayAtividade.push({ idAtividade: atvSelecionada[i], ambiente: amb, ordem: i + 1 })
            }

            //-----------------------------------LOCAIS----------------------

            const temp_rows = dadosLocais.filter(
                (e: any) => {
                    return e.ok === 1
                }
            );


            const newArrLoc = temp_rows.map((item: any) => {
                return {
                    id: item.id,
                    ambiente: amb
                }

            })

            //----------------------------------dataagenda----------------------

            for (i = 0; i < dadosPersonalizado2.length; i++) {
                newArrayDataAgenda.push({ data: dadosPersonalizado2[i], ativo: 1 })
            }

            try {
                ret = await api.post("agenda/insert", {
                    agenda: dados,
                    atividade: newArrayAtividade,
                    locais: newArrLoc,
                    dataagenda: newArrayDataAgenda,
                    userlog: localStorage.getItem("User")
                });

                if (ret.data.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        text: 'Cadastro realizado com sucesso!',
                        confirmButtonColor: "#222d32",
                    })
                    navigate('/agenda');
                }
            } catch (e: any) {
                btnSalvarAgenda.disabled = false;
                Swal.fire({
                    icon: 'error',
                    title: 'ATENÇÃO',
                    text: `Não foi possível concluir o cadastramento erro: ${e.response.data.message}!`,
                    confirmButtonColor: "#222d32",
                })
                btnSalvarAgenda.disabled = false;
            }
        }

    }


    function agendafunc() {

        SalvarTar();


    }

    function cancelarAgenda() {
        navigate('/agenda');
    }

    function dadosLoc(obj: any) {
        setDadosLocais(obj)
    }

    async function insertLocaisAgenda(id: any, tipo: any) {
        let ret: AxiosResponse;
        let newArray = [] as any
        let i = 0
        api.defaults.headers.common['Authorization'] = localStorage.getItem("userToken") || '{}';


        const temp_rows = dadosLocais.filter(
            (e: any) => {
                return e.ok === 1
            }
        );

        const newArr = temp_rows.map((item: any) => {
            return {
                id_agenda: id,
                id: item.ID,
                ambiente: amb
            }

        })

        try {
            ret = await api.post("locaisagenda/insert", {
                data: newArr,
                dataOrig: dadosLocalUP,
                userlog: localStorage.getItem("User")
            });
            if (ret.data.status === 200) {


                if (frequencia !== '10' && dadosPersonalizado2.length === 0 && tipo === 'insert') {


                    Swal.fire({
                        icon: 'success',
                        text: 'Agenda cadastrada com sucesso!',
                        confirmButtonColor: "#222d32",
                    })
                    navigate('/agenda');
                } else if (frequencia !== '10' && dadosPersonalizado2.length === 0 && tipo === 'update') {

                    Swal.fire({
                        icon: 'success',
                        text: 'Agenda atualizada com sucesso!',
                        confirmButtonColor: "#222d32",
                    })
                    navigate('/agenda');
                } else {
                    insertDataAgenda(id, tipo)
                }
            }
        } catch (e: any) {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: `Não foi possível concluir o cadastramento erro: ${e.response.data.message}!`,
                confirmButtonColor: "#222d32",
            })
        }


    }

    async function insertDataAgenda(id: any, tipo: any) {
        let i = 0
        let newArray = []
        let ret: AxiosResponse;
        api.defaults.headers.common['Authorization'] = localStorage.getItem("userToken") || '{}';

        for (i = 0; i < dadosPersonalizado2.length; i++) {
            newArray.push({ data: dadosPersonalizado2[i], idagenda: id, ativo: 1 })
        }

        try {
            ret = await api.post("dataagenda/insert", {
                data: newArray,
                dataOrig: dadosDataAgendaUP,
                userlog: localStorage.getItem("User")
            });
            if (ret.data.status === 200) {

                if (tipo === 'update') {

                    Swal.fire({
                        icon: 'success',
                        text: 'Agenda atualizada com sucesso!',
                        confirmButtonColor: "#222d32",
                    })
                    navigate('/agenda');
                } else if (tipo === 'insert') {


                    Swal.fire({
                        icon: 'success',
                        text: 'Agenda cadastrada com sucesso!',
                        confirmButtonColor: "#222d32",
                    })
                    navigate('/agenda');
                }
            }

        } catch (e: any) {
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: `Não foi possível concluir o cadastramento erro: ${e.response.data.message}!`,
                confirmButtonColor: "#222d32",
            })
        }
    }


    return (
        <>
            <Nav />
            <div className="main-content">
                <Header />
                <main>
                    <div className='content-planejador'>
                        <div className="container-tab">
                            <div className="bloc-tabs">
                                <button
                                    className={toggleState === 1 ? "tabs active-tabs" : "tabs"} onClick={() => setToggleState(1)}
                                >
                                    <div className="containertab-1">
                                        <div id="content-tab1">
                                            <AiOutlineUserAdd className="icone-Modal" />
                                        </div>
                                        <div id="content-tab2">
                                            <h1>O QUE / QUEM</h1>
                                        </div>
                                        <div id="content-tab3">
                                        </div>
                                    </div>
                                </button>
                                <button
                                    className={toggleState === 2 ? "tabs active-tabs" : "tabs"} onClick={() => setToggleState(2)}
                                >
                                    <div className="containertab-1">
                                        <div id="content-tab1">
                                            <AiOutlineCalendar className="icone-Modal" />
                                        </div>
                                        <div id="content-tab2">
                                            <h1>QUANDO</h1>
                                        </div>
                                        <div id="content-tab3">
                                        </div>
                                    </div>
                                </button>
                                <button
                                    className={toggleState === 3 ? "tabs active-tabs" : "tabs"} onClick={() => setToggleState(3)}

                                >
                                    <div className="containertab-1">
                                        <div id="content-tab1">
                                            <GoLocation className="icone-Modal" />
                                        </div>
                                        <div id="content-tab2">
                                            <h1>ONDE</h1>
                                        </div>
                                        <div id="content-tab3">

                                        </div>
                                    </div>
                                </button>
                            </div>

                            <div className="content-tabs">
                                <div
                                    className={
                                        toggleState === 1 ? "content  active-content" : "content"
                                    }
                                >
                                    <div className='container-pad'>

                                        <div className="user-details">
                                            <div className="input-box2">
                                                <div className="user-details2">

                                                    <div className="input-box4">
                                                        <div className="input-ct">
                                                            <input type="checkbox" className="input-check" id="checkbox-ativo" checked={ativo} onChange={ValitarAtivo} />
                                                            <span className="details">ATIVO</span>
                                                        </div>
                                                    </div>

                                                    <div className="input-box4">
                                                        <div className="input-ct">
                                                            <input type="checkbox" className="input-check" id="checkbox-recriar" checked={recriar} onChange={ValitarRecriar} />
                                                            <span className="details">RECRIAR</span>
                                                        </div>

                                                    </div>
                                                    <div className="input-box4">
                                                        <div className="input-ct">
                                                            <input type="checkbox" className="input-check" id="checkbox-desativar" checked={desativar} onChange={ValitarDesativar} />
                                                            <span className="details">DESATIVAR</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="input-box2">
                                                <span className="details">DESCRIÇÃO</span>
                                                <input className="input-descr" type="text" onChange={(e) => setDescricao(e.target.value)}
                                                    value={descricao} />
                                            </div>
                                        </div>

                                        <div className="container-2">
                                            <div id="content1">
                                                <div className="input-box">
                                                    <span className="details">PESSOA</span>
                                                    <select
                                                        id="PESSOA"
                                                        onChange={(e) => PessoaAjuste(e.target.value)}
                                                        value={pessoa}
                                                    >
                                                        <option>Selecione...
                                                        </option>
                                                        {renderPessoa}
                                                    </select>
                                                </div>
                                            </div>
                                            <div id="content2">

                                                <div className="input-box">
                                                    <span className="details">SITUAÇÃO</span>
                                                    <select id="SITUACAO"
                                                        onChange={(e) => SetSituacao(e.target.value)}
                                                        value={situacao}
                                                    >
                                                        <option >Selecione...
                                                        </option>
                                                        {renderSituacao}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="container-2">
                                            <div id="content1">
                                                <div className="input-box">
                                                    <span className="details">EQUIPE</span>
                                                    <select
                                                        id="PESSOAeee"
                                                        onChange={(e) => EquipeAjuste(e.target.value)}
                                                        value={equipe}
                                                    >
                                                        <option>Selecione...
                                                        </option>
                                                        {renderEquipe}
                                                    </select>
                                                </div>
                                            </div>
                                            <div id="content2">
                                                <div className="input-box">
                                                    <span className="details">MODO DE EQUIPE</span>
                                                    <select id="MODOEQP"
                                                        onChange={(e) => FormaAjuste(e.target.value)}
                                                        value={forma}
                                                    >
                                                        <option>Selecione...
                                                        </option>
                                                        {renderFormaEquipe}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="container-2">
                                            <div id="content1">
                                                <div className="input-box">
                                                    <span className="details">TIPO DE TAREFA</span>
                                                    <select id="TIPOTAREFA"
                                                        onChange={(e) => SetTipoTarefa(e.target.value)}
                                                        value={tipoTarefa}
                                                    >
                                                        <option>Selecione...
                                                        </option>
                                                        {renderTipoTarefa}
                                                    </select>
                                                </div>
                                            </div>
                                            <div id="content2">
                                                <div className="input-box">
                                                    <span className="details">MODO DE ATIVIDADE</span>
                                                    <select id="MODOATV"
                                                        onChange={ModoAtividadeAjuste}
                                                        value={modoAtividade}
                                                    >
                                                        <option>Selecione...
                                                        </option>
                                                        {renderModoAtividade}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="container-2">
                                            <div id="content1">
                                                <div className="input-box">
                                                    <span className="details">SUBTIPO DE TAREFA</span>
                                                    <select id="SUBTIPO"
                                                        onChange={(e) => SetSubtipo(e.target.value)}
                                                        value={subtipo}
                                                    >
                                                        <option>Selecione...
                                                        </option>
                                                        {renderSubtipoTarefa}
                                                    </select>
                                                </div>
                                            </div>
                                            <div id="content2">
                                                <div className="input-box">
                                                    <span className="details">INSTRUÇÃO</span>
                                                    <input
                                                        type="text"
                                                        onChange={(e) => SetObservacao(e.target.value)}
                                                        value={observacao}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="container-4">
                                            <div id="content1">
                                                <div className="input-box">
                                                    <span className="details-list">ATIVIDADES DISPONÍVEIS:</span>
                                                </div>
                                            </div>
                                            <div id="content2">
                                                <div className="input-box">
                                                    <span className="details-list-esc">ATIVIDADES SELECIONADAS:</span>
                                                </div>
                                            </div>
                                        </div>

                                        <DualListBox
                                            options={options}
                                            selected={atvSelecionada}
                                            onChange={valor}
                                            icons={{
                                                moveLeft: "<",
                                                moveAllLeft: "<<",
                                                moveRight: ">",
                                                moveAllRight: ">>"
                                            }}
                                            preserveSelectOrder={true}
                                        />

                                    </div>
                                </div>
                                <div
                                    className={
                                        toggleState === 2 ? "content  active-content" : "content"
                                    }
                                >
                                    <div className='container-pad'>
                                        <div className="container-3">
                                            <div id="content1">
                                                <div className="input-box">
                                                    <span className="details">DATA INICIAL</span>
                                                    <input type="date" id="DATAINI"
                                                        value={dataIncial}
                                                        onChange={Frequencia} />
                                                </div>
                                            </div>
                                            <div id="content2">
                                                <div className="input-box">
                                                    <span className="details">HORA INICIAL</span>

                                                    <input type="time" id="TIMEINI" onChange={Frequencia}
                                                        value={horaIncial}
                                                    ></input>
                                                </div>
                                            </div>
                                            <div id="content3">
                                                <div className="input-box">
                                                    <span className="details">PROX. EXECUÇÃO</span>
                                                    <input
                                                        type="text" disabled
                                                        id="PROX"
                                                        onChange={(e) => setProximaExec(e.target.value)}
                                                        value={proximaExec}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="container-3">
                                            <div id="content1">
                                                <div className="input-box">
                                                    <span className="details">DATA FIM</span>
                                                    <input type="date" id="DATAFIM" onChange={validaDataFim} value={datafim}></input>
                                                </div>
                                            </div>
                                            <div id="content2">
                                                <div className="input-box">
                                                    <span className="details">HORA FIM</span>
                                                    <input type="time" id="TIMEFIM" onChange={Frequencia} value={horafim}></input>
                                                </div>
                                            </div>
                                            <div id="content3">
                                                <div className="input-box">
                                                    <span className="details">ANTECIPAR TAREFA</span>
                                                    <input
                                                        onChange={(e) => SetAntecipar(e.target.value)}
                                                        value={antecipar}
                                                        type="number"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="container-3">
                                            <div id="content1">
                                                <div className="input-box">
                                                    <span className="details">FREQUÊNCIA</span>
                                                    <select id="FREQUEC"
                                                        onChange={Frequencia}
                                                        value={frequencia}>
                                                        <option hidden>Selecione...
                                                        </option>
                                                        {renderFrequencia}
                                                    </select>
                                                </div>
                                            </div>
                                            <div id="content2">
                                                <div className="input-box">
                                                    <span className="details">QUANTIDADE</span>
                                                    <input
                                                        type="number"
                                                        onChange={Frequencia}
                                                        id="validar-freqdias"
                                                        value={freq_dias}
                                                    />
                                                </div>
                                            </div>
                                            <div id="content3">
                                                <div className="input-box">
                                                    <span className="details">ANTECIPAR NOT.</span>
                                                    <input
                                                        onChange={(e) => SetMsgDia(e.target.value)}
                                                        value={msgDia}
                                                        type="number"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {email === true ?
                                            <div className="input-box">
                                                <span className="details">E-MAILS (Separar por ponto e virgula)</span>
                                                <input
                                                    type="text"
                                                    onChange={(e) => setMail(e.target.value)}
                                                    value={mail}
                                                />
                                            </div> : null}

                                        <div className="user-details">
                                            <div className="input-box3">
                                                <div className="input-c">
                                                    <input type="checkbox" id="checkbox-D1" checked={d1} onChange={Frequencia} />
                                                    <span className="details">DOMINGO</span>
                                                </div>

                                                <div className="input-c">
                                                    <input type="checkbox" id="checkbox-D2" checked={d2} onChange={Frequencia} />
                                                    <span className="details">SEGUNDA - FEIRA</span>
                                                </div>

                                                <div className="input-c">
                                                    <input type="checkbox" id="checkbox-D3" checked={d3} onChange={Frequencia} />
                                                    <span className="details">TERÇA - FEIRA</span>
                                                </div>

                                                <div className="input-c">
                                                    <input type="checkbox" id="checkbox-D4" checked={d4} onChange={Frequencia} />
                                                    <span className="details">QUARTA - FEIRA</span>
                                                </div>

                                                <div className="input-c">
                                                    <input type="checkbox" id="checkbox-D5" checked={d5} onChange={Frequencia} />
                                                    <span className="details">QUINTA - FEIRA</span>
                                                </div>

                                                <div className="input-c">
                                                    <input type="checkbox" id="checkbox-D6" checked={d6} onChange={Frequencia} />
                                                    <span className="details">SEXTA - FEIRA</span>
                                                </div>

                                                <div className="input-c">
                                                    <input type="checkbox" id="checkbox-D7" checked={d7} onChange={Frequencia} />
                                                    <span className="details">SÁBADO</span>
                                                </div>
                                            </div>
                                            <div className="input-box3">
                                                <div className="input-c">
                                                    <input type="checkbox" id="checkbox-pular-sab" checked={pular_sab} onChange={ValidarPular_sab} />
                                                    <span className="details">PULAR SÁBADO</span>
                                                </div>

                                                <div className="input-c">
                                                    <input type="checkbox" id="checkbox-pular-dom" checked={pular_dom} onChange={ValidarPular_dom} />
                                                    <span className="details">PULAR DOMINGO</span>
                                                </div>
                                            </div>
                                            <div className="input-box3">
                                                <div className="input-c">
                                                    <input type="checkbox" id="checkbox-notificacao" checked={notificacao} onChange={ValitarNotificacao} />
                                                    <span className="details">NOTIFICAÇÃO</span>
                                                </div>

                                                <div className="input-c">
                                                    <input type="checkbox" id="checkbox-sms" checked={sms} onChange={ValitarSMS} />
                                                    <span className="details">SMS</span>
                                                </div>
                                                <div className="input-c">
                                                    <input type="checkbox" id="checkbox-email" checked={email} onChange={ValitarEMail} />
                                                    <span className="details">E-MAIL</span>
                                                </div>

                                                <div className="input-c">
                                                    <input type="checkbox" id="checkbox-tts" checked={tts} onChange={ValitarTTS} />
                                                    <span className="details">TTS</span>
                                                </div>
                                                <div className="input-c">
                                                    <input type="checkbox" id="checkbox-noti_umov" checked={noti_umov} onChange={ValitarNoti_umov} />
                                                    <span className="details">NOTIFICAÇÃO UMOV</span>
                                                </div>
                                            </div>



                                        </div>
                                        {frequencia10 === 'S' ?
                                            <PersonalizadoData frequencia={frequencia} personalizado2func={personalizado2func} dataIncial={dataIncial} />
                                            : null}
                                    </div>
                                </div>

                                <div
                                    className={
                                        toggleState === 3 ? "content  active-content" : "content"
                                    }
                                >
                                    <div className='container-pad'>
                                    <GridLocais
                                            th1='ATIVO'
                                            th2='ID'
                                            th3='DESCRIÇÃO'
                                            th4='FREQUÊNCIA'
                                            th5='HORA'
                                            th6='ÚLTIMA EXECUÇÃO'
                                            th7='PROXIMA EXECUÇÃO'
                                            endPoint='locais/listarlocais'
                                            params1={amb}
                                            params2={''}
                                            col1='7'
                                            col2='0'
                                            col3='1'
                                            col4='2'
                                            carregalocal={carregalocal}
                                            dadosLoc={dadosLoc}
                                        />


                                    </div>

                                </div>
                                <div className="Bt-button-planejador">
                                    <button id="btnSalvarAgenda" type="submit" onClick={agendafunc}>
                                        <i className="fa fa-check-circle icon-ok-btn"></i> Salvar
                                    </button>
                                    <button type="submit" onClick={cancelarAgenda}>
                                        <i className="fa fa-times icon-cancelar-btn"></i> Cancelar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );

}

export default PlanejadorAdd;