import React from "react";
import "./index.css";
import { useNavigate } from 'react-router';

const Header = (props: any) => {
  const navigate = useNavigate();
  
  const handleLogoff = () => {
    navigate('/');
    localStorage.removeItem("PrivateRoute");
  };

  return (
    <div className="main-content">
      <header>
        <h2>
          <label htmlFor="nav-toggle">
            <span className="fa fa-bars ico"></span>
          </label>
        </h2>

        <div className="user-wrapper">
          <div>
            <h4>{localStorage.getItem("User")}</h4>
            <small onClick={handleLogoff}>Sair</small>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
