import {
    BrowserRouter,
    Routes,
    Route,
    Navigate,
  } from "react-router-dom";
  
  import Dashboard from "../pages/Dashboard"
  import Login from "../pages/Login";
  import NoPage from "../pages/NoPage"

  import VisualizarUsuarios from '../pages/Usuarios'
  import UsuariosAdd from "../pages/UsuariosAdd";
  import UsuariosEdit from "../pages/UsuariosEdit";

  import VisualizarAgendas from '../pages/Planejador'
  import AgendasAdd from "../pages/PlanejadorAdd";
  import AgendasEdit from "../pages/PlanejadorEdit";

export const Router = ()=>{
  
  const PrivateRoute = ({ children, redirectTo }: {children: any, redirectTo:any }) => {
    //ATENÇÃO CRIAR um temporizador" para checar token espirou toda vez que realizar um requisição valida ele atualiza
    const isAuthenticated = localStorage.getItem("PrivateRoute") !== null;
    return isAuthenticated ? children : <Navigate to={redirectTo} />;   
  };


      return(
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login/>}/>
            <Route path="/" element={<Login/>}/> 
            <Route path="/dashboard" element={<PrivateRoute redirectTo="/"><Dashboard /></PrivateRoute>}/>

            <Route path="/user" element={<PrivateRoute redirectTo="/"><VisualizarUsuarios /></PrivateRoute>}/>
            <Route path="/user/add" element={<PrivateRoute redirectTo="/"><UsuariosAdd /></PrivateRoute>}/>
            <Route path="/user/edit/:id" element={<PrivateRoute redirectTo="/"><UsuariosEdit /></PrivateRoute>}/>
      
            <Route path="/agenda" element={<PrivateRoute redirectTo="/"><VisualizarAgendas /></PrivateRoute>}/>
            <Route path="/agenda/add/:amb" element={<PrivateRoute redirectTo="/"><AgendasAdd /></PrivateRoute>}/>
            <Route path="/agenda/edit/:amb/:id" element={<PrivateRoute redirectTo="/"><AgendasEdit /></PrivateRoute>}/>

            <Route path="*" element={<NoPage />} />
          </Routes>
        </BrowserRouter>
      )
    }
export default Router
  