import React,{useEffect, useState} from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import GridPrincipal from '../../components/GridPrincipal'
import Swal from 'sweetalert2';
import { AxiosResponse } from "axios";
import api from "../../Services/api";

function Usuarios() {

    const [exibirGrid, setExibirGrid] = useState(<></>);

    useEffect(() => {
        setExibirGrid(<GridPrincipal
            iconeExcluir={ModalDeletar}
            th1='Ativo'
            th2='ID'
            th3='Nome'
            th4='Login'
            th5='Data da Inclusão'
            th6='Data Atualização'
            endPoint='user/listaruser'
            consult='N'
            col1='5'
            col2='0'
            col3='2'
            col4='3'
            col5='20'
            col6='21'
            ambiente={localStorage.getItem("ambiente")}
            fromAdd='/user/add'
            fromEdit={`/user/edit`}
        />)
    }, []);

    function ModalDeletar(userobj: any) {
    
        Swal.fire({
            title: 'Deseja deletar o registro?',
            text: "Você não poderá reverter isso!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#222d32',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then((result) => {
            if (result.isConfirmed) {
              deletarUser(userobj.use_id)
            }
          })
    }

    async function deletarUser(id: any) {
        let ret: AxiosResponse;
        setExibirGrid(<></>)
        try {
          ret = await api.delete("user/delete", {
            data: { id: id }
          });
          if (ret.data.status === 200) {
    
            Swal.fire({
              icon: 'success',
              text: 'O usuário foi excluída com sucesso!',
              confirmButtonColor: "#222d32",
            })
            setExibirGrid(<GridPrincipal
                iconeExcluir={ModalDeletar}
                th1='Ativo'
                th2='ID'
                th3='Nome'
                th4='Login'
                th5='Data da Inclusão'
                th6='Data Atualização'
                endPoint='user/listaruser'
                consult='N'
                col1='5'
                col2='0'
                col3='2'
                col4='3'
                col5='20'
                col6='21'
                ambiente={localStorage.getItem("ambiente")}
                fromAdd='/user/add'
                fromEdit={`/user/edit`}
            />)
          }
        } catch (e: any) {
          if (e.response.status === 401) {
    
          } else {
    
          }
        }
      }

    return (
        <>
        <Nav />
        <div className="main-content">
        <Header />
         <main>
            <div className='containerPrincipal'>
            <div className='div-titulo-pages'>
                 <span className='fa fa-user' />
                    <h1 className='div-titulo-pages-text'>Usuários</h1>
                </div>
                 {exibirGrid}
            </div>
            </main>  
         </div>
        </>
    );

}

export default Usuarios;