import axios from 'axios';

const api = axios.create({
    baseURL: 'https://apiplanejador.adlim.com.br:3333/'
    //http://192.168.5.17:3333/
    //'http://localhost:3333/'
    // 'https://apiplanejador.adlim.com.br:3333/'
    //http://192.168.5.101:3333/
    //
})
//acesso planejador.adlim.com.br

export default api;

