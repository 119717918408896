import styled from "styled-components";


interface ITagProps {
  color: string;
}


export const Container = styled.li`
justify-content: space-between;
list-style: none;
padding: 5px 20px 5px;
display: flex;
align-items: center;
transition: all .3s;
position: relative;
margin-left: -10px;
background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);
margin-bottom: 4px;
border: 1px solid #bbb;
border-radius: 6px;
margin-left: 1px;
  .container-list1 {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-areas: "content1";

  }

  #content-list1 {
    grid-area: content1;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 14px;
  }
  
  #content-list2 {
    grid-area: content2;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 14px;
    margin-left: 5px;
  }
  
  #content-list3 {
    grid-area: content3;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 14px;
    margin-left: 10px;
  }

&:hover{
  border: 1px solid #000000;
}

.nome-list{
  margin-left: 5px;
}

.span-nome{
font-size: 14px;
}

.span-result{
  font-size: 12px;
  font-weight: normal;
}

.span-inf{
font-size: 15px;
color: #000000
}

.icone-resumo:hover {
    opacity: 0.8;
  }
  

> div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 10px;
}

.icone-resumo{
  color: red;
  cursor: pointer;
  font-size: 20px;
}

@media only screen and (max-width: 560px) {
	
  .container-list1{
    grid-template-columns: 1fr;
    grid-template-areas: 
    "content1" 
    "content2" 
    "content3"
    "content4";
  }

  #content-list2{
   margin-top: 2px;
   }

 #content-list2{
	 margin-left: 0px;
   margin-top: 2px;
   }

  #content-list3{
	 margin-left: 0px;
   margin-top: 2px;
   }

}

`;

export const Tag = styled.div<ITagProps>`
    height: 13px;
    width: 13px;
    border-radius: 50%;
    background-color: ${props => props.color};
    position: absolute;
    left: 10px;
`;

export const Title = styled.div`
 text-align: left;
 font-weight: bold;
 word-break: break-all;

 span{
  font-size: 11px;
 }
 
 .title-senha{
   margin-left: 10px;
   margin-right: 10px;
 }

`
export const ContResumo = styled.div`
margin: 0 0 0 10px;

  .text{
    margin-top: 1px
  }
  span{
    text-transform: uppercase;
    font-size: 13px; 
  }
  b{
    font-size: 13px; 
  }
`
export const TitleClassif = styled.div`
  margin-top: 25px;
  text-align: center;

  h2{
    font-size: 13px; 
    margin-bottom: 5px;
    text-transform: uppercase;
  }
 
  input{
    margin: 5px;
    font-weight: bold;
  }
`

export const Tmoda = styled.div`
margin: 10px;
  .text{
    margin-top: 7px
  }
`

export const ButtonContainer = styled.div`
   width: 80%;
   margin-top: 20px;
   align-items: center;
`