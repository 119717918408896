
import React,{useEffect, useState} from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import WalletBox from '../../components/WalletBox';
import "./index.css";
import api from '../../Services/api'
import { AxiosResponse } from "axios";
import Swal from 'sweetalert2';

function Dashboard() {

    const [quantUser, setQuantUser] = useState('');
    const [quantAgenda, setQuantAgenda] = useState('');

    useEffect(() => {
        Usuarios();
        Agenda();
    }, []);

    async function Usuarios() {
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("user/listaruser", {
                params: {
                    ambiente: localStorage.getItem("ambiente")
                }
            })
            if (ret.status === 200) {
                valor = ret.data.data.length;
                setQuantUser(valor);
                
            }
        } catch (e:any) { 
 
          
          
        }
    }


    async function Agenda() {

   
        api.defaults.headers.common['Authorization'] = localStorage.getItem("userToken") || '{}';
        
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("agenda/listaragenda", {
                params: {
                    ambiente: localStorage.getItem("ambiente")
                }
            })
            if (ret.status === 200) {
                valor = ret.data.data.length;
                setQuantAgenda(valor);
              
            }
        } catch (e:any) {
     
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: `Não foi possível concluir o cadastramento erro: ${e.response.data.message}!`,
                confirmButtonColor: "#222d32",
            })
          
         }
    }


    return (
        <>
            <Nav />
            <div className="main-content">
                <Header />
                <main>
          
                </main>
            </div>
        </>
    );

}

export default Dashboard;