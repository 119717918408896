import React, { useState, useEffect } from "react";
import "line-awesome/dist/line-awesome/css/line-awesome.css";
import "./index.css";
import logomenu from '../../img/now.png'
import { useNavigate } from 'react-router';
import { FaChartArea, FaUser, FaUsers, 
  FaClipboardList, FaCalendarAlt, 
  FaChevronDown, FaWindowRestore, FaCity, FaList, FaExchangeAlt, FaFileAlt,
  FaLayerGroup, FaFileInvoice } from "react-icons/fa";
  import { MdExitToApp } from "react-icons/md";
  
const Nav = () => {
  const [sucesso, setSucesso] = useState('S');
  const navigate = useNavigate();

  function Inicio() {
    localStorage.setItem("form", 'user');
    localStorage.setItem("titulo", 'Usuários');
    navigate('/dashboard');
  }

  function User() {
    localStorage.setItem("form", 'user');
    localStorage.setItem("titulo", 'Usuários');
  }

  function Planejador() {
    localStorage.setItem("form", 'planejador');
    localStorage.setItem("titulo", 'Planejador');
    localStorage.removeItem("AMBLOC");

  }

  function Portal() {
    localStorage.setItem("form", 'portal');
    localStorage.setItem("titulo", 'Portal de chamados');
  }

  function Chamados() {

  }

  function Relatorios() {

  }

  return (
    <>
      <input type="checkbox" id="nav-toggle" />
      {/* Side bar  menu */}
      <div className="sidebar">
        <div className="sidebar-brand">

          <div className="logimg"><img className="logoNav" src={logomenu} alt="logo" width={180} height={60} /></div>

        </div>
        <div className="sidebar-menu">
          <ul>
            <li className="side">
              <a onClick={Inicio}>
              <span className='ControleIco'><FaChartArea/></span>
                <span className="lab-icone">Inicio</span>
              </a>
              <h1 className="Legenda-side">Inicio</h1>
            </li>


            {localStorage.getItem("usertype") !== 'Operador' ?

              <li className="side">
                <a href="/user" onClick={User}>
                <span className="ControleIcon"><FaUser /></span>
                  <span className="lab-icone">Usuários</span>
                </a>
                <h1 className="Legenda-side">Usuários</h1>
              </li>

              : null}

            <li className="side">
              <a href="/agenda" onClick={Planejador}>
              <span className='ControleIco'><FaCalendarAlt /></span>
                <span className="lab-icone">Planejador</span>
              </a>
              <h1 className="Legenda-side">Planejador</h1>
            </li>

            <li className="side">
              <a href='/'>
              <span className='ControleIcon'><MdExitToApp /></span>
                <span className="lab-icone">Sair</span> </a>
              <h1 className="Legenda-side">Sair</h1>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Nav;
