import React, { useState, useEffect } from "react";
import "./index.css";
import Listagem from "../Listagem";
import Swal from 'sweetalert2';

function PersonalizadoData(props) {
					
  const dadosAgenda = (localStorage.getItem("dadosPersonalizado2") || '{}');
  let ageObj = JSON.parse(dadosAgenda);

  let [lista, setLista] = useState(ageObj || []);
  let [dataItem, setDataItem] = useState("");
  let [horaItem, setHoraItem] = useState("");

  useEffect(() => {
    props.personalizado2func(lista)
   }, []);

  function adicionarNovoItem(){
    let ddd = dataItem + ' ' + horaItem
      if (dataItem === ''){
        Swal.fire({
          icon: 'error',
          title: 'ATENÇÃO',
          text: 'Informe a data!',
          confirmButtonColor: "#222d32",
      })
        
      }else if (horaItem === ''){
        Swal.fire({
          icon: 'error',
          title: 'ATENÇÃO',
          text: 'Informe a hora!',
          confirmButtonColor: "#222d32",
      })
      }else if (dataItem < props.dataIncial){
        Swal.fire({
          icon: 'error',
          title: 'ATENÇÃO',
          text: 'A data personalizada não pode ser menor que a data inicial.',
          confirmButtonColor: "#222d32",
      })
      }  
      else{
        setLista([...lista, ddd]);
        setDataItem("");
        setHoraItem("");

        let tmArray = [...lista, ddd];

        props.personalizado2func(tmArray)
        
      }
    
}

function deletarItem(index){
  let tmArray = [...lista];
  tmArray.splice(index, 1);
  setLista(tmArray);
 props.personalizado2func(tmArray)
}

  return (
    <>
      <div className="Container-data">
        <div className="user-details">
        
          <div className="input-itens">
            <span className="details">Data</span>
            <input
             value={dataItem} onChange={value => setDataItem(value.target.value)} type="date"
            />
          </div>
          <div className="input-itens">
            <span className="details">Hora</span>
            <input
             value={horaItem} onChange={value => setHoraItem(value.target.value)} type="time"
            />
          </div>
          <div className="input-itens">
            <button className="button_itens" onClick={()=> adicionarNovoItem()}>
              ADICIONAR
            </button>
          </div>
        </div>
        
          <ul className="listagem">
          
          {lista.map((item,index) => (
          <Listagem
          key={index}
          data={item}
          funcao={deletarItem}
        />
          ))}
        </ul>
      </div>
     
    </>
  );
};

export default PersonalizadoData;
