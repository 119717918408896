import { Container, Tag, Title } from './styles';
import React, { useState } from 'react';
import { AiFillDelete } from "react-icons/ai";


interface IListagemProps {
  data: string;
  funcao: any;
}

const Listagem: React.FC<IListagemProps> = ({
  data,
  funcao
}) => {

  let tagColor: string;

  tagColor = '#ff1100'

  return (
    <>
      <Container>
        <div>
          <Title>
            <div className="container-list1">
              <div id="content-list1">
                <span className='span-inf'>{data}</span>
              </div>
            </div>
          </Title>
        </div>
        <div className='icone-resumo'>
          <AiFillDelete onClick={funcao}/>
        </div>
      </Container>
    </>
  );
}

export default Listagem;