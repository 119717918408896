import React, { useEffect, useState } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import GridPrincipal from '../../components/GridPrincipal'
import { AxiosResponse } from "axios";
import api from "../../Services/api";
import "./index.css";
import Loading from '../../components/Loading';
import Swal from 'sweetalert2';

function Planajeador() {

    const [exibirGrid, setExibirGrid] = useState(<></>);
    const [company, setCompany] = useState('');
    const [dadosCompany, setDadosCompany] = useState([]);
    const [carregaFormaEquipe, setCarregaFormaEquipe] = useState(GetCompany);
    const [qualAmbiente, setQualAmbiente] = useState("")


    useEffect(() => {
       
        usuarioseditar('')
    }, []);

    async function usuarioseditar(ambiente: any) {
        
        let ret: AxiosResponse;
        let valor: any;
        api.defaults.headers.common['Authorization'] = localStorage.getItem("userToken") || '{}';
        
        try {
            ret = await api.get("user/validar")
       
            if (ret.data.status === 200) {
             
                if (ret.data.data.use_usertype === 'Administrador') {
                    if (company === "") {
                        setQualAmbiente("%")
                      
                        setExibirGrid(<GridPrincipal
                            iconeExcluir={ModalDeletar}
                            th1='Ativo'
                            th2='ID'
                            th3='Descrição'
                            th4='Frequência'
                            th5='Última Exec.'
                            th6='Próxima Exec.'
                            col1='2'
                            col2='0'
                            col3='1'
                            col4='42'
                            col5='24'
                            col6='23'
                            endPoint='agenda/listaragenda'
                            consult='N'
                            ambiente={ambiente}
                            fromAdd={`/agenda/add`  + '/' + ambiente}
                            fromEdit={'/agenda/edit' + '/' + ambiente}
                        />)
                    } else {
                
                        setExibirGrid(<GridPrincipal
                            iconeExcluir={ModalDeletar}
                            th1='Ativo'
                            th2='ID'
                            th3='Descrição'
                            th4='Frequência'
                            th5='Última Exec.'
                            th6='Próxima Exec.'
                            col1='2'
                            col2='0'
                            col3='1'
                            col4='42'
                            col5='24'
                            col6='23'
                            endPoint='agenda/listaragenda'
                            consult='N'
                            ambiente={ambiente}
                            fromAdd={`/agenda/add`  + '/' + ambiente}
                            fromEdit={'/agenda/edit' + '/' + ambiente}                       
                        />)
                    }

                } else if (ret.data.data.use_usertype === 'Operador' || ret.data.data.use_usertype === 'Padrao') {
                    setExibirGrid(<GridPrincipal
                        iconeExcluir={ModalDeletar}
                        th1='Ativo'
                        th2='ID'
                        th3='Descrição'
                        th4='Frequência'
                        th5='Última Exec.'
                        th6='Próxima Exec.'
                        col1='2'
                        col2='0'
                        col3='1'
                        col4='42'
                        col5='24'
                        col6='23'
                        endPoint='agenda/listaragenda'
                        consult='N'
                        ambiente={ret.data.data.use_company}
                        fromAdd={`/agenda/add` + '/' + ret.data.data.use_company}
                        fromEdit={'/agenda/edit' + '/' + ret.data.data.use_company}
                    />)
                }
            }
        } catch (e) {

        }
    }


    function ModalDeletar(userobj: any) {
        Swal.fire({
            title: 'Deseja deletar o registro?',
            text: "Você não poderá reverter isso!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#222d32',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then((result) => {
            if (result.isConfirmed) {
              deletarAgenda(userobj.id);
              setExibirGrid(<Loading />)
            }
          })
    }

    async function deletarAgenda(id: any) {

        let ret: AxiosResponse;
    
        const dados = {
          id: id,
          userlog: localStorage.getItem("User")
        }
    
        try {
          ret = await api.delete("agenda/delete", {
               data: dados}
          );
          if (ret.data.status === 200) {
            Swal.fire({
              icon: 'success',
              text: 'Agenda foi excluída com sucesso!',
              confirmButtonColor: "#222d32",
            })
            usuarioseditar('');
            setCompany("")
          }
        } catch (e: any) {
          
        }
      }
    
    async function GetCompany() {
        api.defaults.headers.common['Authorization'] = localStorage.getItem("userToken") || '{}';
        let ret: AxiosResponse;
        let valor: any;
        try {
            ret = await api.get("ambiente/listarambiente")

            if (ret.status === 200) {
                valor = ret.data.data;
                setDadosCompany(valor);
            }
        } catch (e) { }
    }


    const renderAmbiente = dadosCompany.map((comp: any) => {
        return (
            <option key={comp.empresa} value={comp.empresa}>
                {" "}
                {comp.empresa}{" "}
            </option>
        );

    });

    async function Ambiente(ambiente: any) {
        if (ambiente === 'AMBIENTE') {
            setCompany('')
        } else {        
            setCompany(ambiente)
            setExibirGrid(<Loading />)
            usuarioseditar(ambiente)
        }
    }
    
    return (
        <>
            <Nav />
            <div className="main-content">
                <Header />
                <main>
                    <div className='containerPrincipal'>
                        <div className='div-titulo-pages'>
                            <span className='fa-solid fa-calendar-days' />
                            <h1 className='div-titulo-pages-text'>Planejador</h1>
                            {qualAmbiente === '%' ?
                                <div className="input-box-amb">
                                    <select
                                        id="AMB"
                                        value={company}
                                        onChange={(e) => Ambiente(e.target.value)}>

                                        <option> AMBIENTE
                                        </option>
                                        {renderAmbiente}
                                    </select>
                                </div> : null}
                        </div>
                        {exibirGrid}
                    </div>
                </main>
            </div>
        </>
    );

}

export default Planajeador;