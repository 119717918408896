import React, { PureComponent, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import "./index.css";
import api from "../../Services/api";
import { useNavigate } from "react-router";
import { FcSearch } from "react-icons/fc";
import { FcPlus } from "react-icons/fc";
import { AxiosResponse } from "axios";
import GridAgenda from "../arquivo/GridAgenda";

export class Child extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      tableData: [],
      orgtableData: [],
      perPage: 10,
      currentPage: 0,
      pagAtual: 1,
      length: 0,
      endpoint: this.props.endPoint,
      clicked: false,
      atualiza: 0,
      search: '',
      isChecked: [],
      consult: this.props.consult,
      forcePage: 0
    };

    this.handlePageClick = this.handlePageClick.bind(this);
  }



  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
        pagAtual: selectedPage + 1,
        forcePage: selectedPage
      },
      () => {
        this.loadMoreData();
      }
    );


  };

  loadMoreData() {

    const data = this.state.orgtableData;

    const slice = data.slice(
      this.state.offset,
      this.state.offset + this.state.perPage,

    );

   
//////////////////////aqui
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      tableData: slice,
      length: data.length,
    });

  }


  handlePageClick_clear = () => {
    const selectedPage = 0;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
        pagAtual: selectedPage + 1,
        forcePage: selectedPage,
      },
      () => {
        this.loadMoreData();
      }
    );
  };

  handleInputChange = (event) => {
    this.setState({ search: event.target.value });
	};

  do_search = () => {
    //------------------------------BUSCAR
    
    if (this.state.search === '' || this.state.search === undefined || this.state.search === null) {
      this.clear_search();
    } else {
      const temp_rows = this.state.orgtableData.filter(
        (e) => {
        
          return (
            JSON.stringify(e)   
              .toLowerCase()
              .indexOf(this.state.search.toLowerCase()) >= 0
          );
          
        }

      );


      var slice = temp_rows.slice(
        this.state.offset,
        this.state.offset + this.state.perPage
      );

      this.setState({
        pageCount: Math.ceil(temp_rows.length / this.state.perPage),
        orgtableData:  temp_rows,
        tableData: slice,  
        length: temp_rows.length,
        pagAtual: 0,
      });
      
    this.handlePageClick_clear()
    }

   
  };

  clear_search = () => {
    //------------------------------LIMPAR
    this.setState({ search: '' });

    var slice = this.state.dataT.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );

    this.setState({
      pageCount: Math.ceil(this.state.dataT.length / this.state.perPage),
      orgtableData: this.state.dataT,
      tableData: slice,
      length: this.state.dataT.length,
      pagAtual: 0,
      forcePage: 0,
    });

    this.handlePageClick_clear();

  };

  componentDidMount() {
    this.getData();
   //this.clear_search();
  }

  getData() {
    api.defaults.headers.common['Authorization'] = localStorage.getItem("userToken") || '{}';
    if (this.props.ambiente !== '') {
      try {
        api.get('ambiente/listarambiente').then((res) => {
  
          const temp_rows = res.data.data.filter(
            (e) => {
                return e.empresa === this.props.params1
            }
        );

        if (temp_rows.length > 0) {
          try {
            api.get(this.props.endPoint, {
              params: {
                ambiente: this.props.params1,
                id: this.props.params2
              }
            }).then((res) => {
    
          
              var data = res.data.data.map((element)=>{return {ok: 0, ...element}});
    
              if (res.data.status === 200) {
    
    
                var slice = data.slice(
                  this.state.offset,
                  this.state.offset + this.state.perPage
                );
    
                this.setState({
                  pageCount: Math.ceil(data.length / this.state.perPage),
                  orgtableData: data,
                  tableData: slice,
                  dataT:data,
                  length: data.length,
                });
                this.props.dadosLoc(data)
              }else{
              }
            });
          }
          catch (e) { 
            
          }
        
        }
         
        });
      }
      catch (e) {

       }
    }
  }


  getDataatualizar(teste) {
          var data = teste;
            var slice = data.slice(
              this.state.offset,
              this.state.offset + this.state.perPage
            );

            this.setState({
              pageCount: Math.ceil(data.length / this.state.perPage),
              orgtableData: teste,
              tableData: slice,
              dataT: teste,
              length: data.length,
            });
  }


  toggle = (index) => {
    if (this.state.clicked === index) {
      //if clicked question is already active, then close it
      return this.setState({ clicked: null });
    }
    this.setState({ clicked: index });
  };

  handlecheckbox = (e,i) => {
    const { value, checked } = e.target;

    if (checked) {
      
      this.setState({
        isChecked: ([...this.state.isChecked, value])
      });
      this.props.dadosLoc(this.state.dataT)
      return 1;
      
    } else {
      this.setState({
        isChecked: (this.state.isChecked.filter((e) => e !== value))
      });
      this.props.dadosLoc(this.state.dataT)
      return 0;
    } 
  }

  render() {
    return (
      <div className="ContainerGrid">
        <div className="ContainerBody">
          <div className="InlineContainery">


            <div className="InputBusca">
              <input
                type="text"
                placeholder="Pesquisar..."
                onChange={this.handleInputChange}
                value={this.state.search}
              />

              <div className="icone-buscaT" onClick={this.do_search}>
                <FcSearch className="icone-add"/>
              </div>

              <div className="icone-buscaT"  onClick={this.clear_search}>
                <i
                  className="fa-solid fa-eraser icone-add"
                ></i>
              </div>
            </div>


          </div>
        </div>

        {this.state.pageCount > 0 ? (
          <div className="grid-tab">
            <table>
              <tbody>
                <tr id="header-loc">
                  <th className="Title-loc">ADICIONAR</th>
                  <th className="Title-loc">QR CODE</th>
                  <th className="Title-loc">DESCRIÇÃO</th>
                  <th className="Title-loc">PAVIMENTO</th>
                </tr>
              </tbody>
              <tbody>
                {this.state.tableData.map((tdata, i) => (
                  <tr className="TR-Coluna" key={Object.values(tdata)[this.props.col1]}>
                    <td className="ColunaCheck-loc" align="center"><input type='checkbox' checked={Object.values(tdata)[0]} onChange={(e) => {this.state.orgtableData[i+((this.state.pagAtual-1)*10)].ok = this.handlecheckbox(e,i)}}/></td>
                    <td className="Coluna-loc">{Object.values(tdata)[this.props.col3]}</td>
                    <td className="Coluna-loc">{Object.values(tdata)[this.props.col4]}</td>
                    <td className="Coluna-loc">{Object.values(tdata)[3]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
        <div className="ContainerPag">

          <div className="InlineContainery">

          {this.state.pageCount === null || this.state.pageCount === 0 ? (
              <div className="SemResut">
                <h2>
                  Nenhum resultado encontrado.
                </h2>
                <h2>
                  Página 1 de 1
                </h2>
              </div>
            ) : null}


            <div className="InputBusca">
              {this.state.pageCount > 0 ? (
                <div className="Quant">
                  <h2>
                    Página {this.state.pagAtual} de {this.state.pageCount}
                  </h2>
                </div>
              ) : null}

              {this.state.pageCount > 1 ? (
                <ReactPaginate
                  previousLabel={<i className="fas fa-angle-left"></i>}
                  nextLabel={<i className="fas fa-angle-right"></i>}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              ) : null}
            </div>
          </div>
          {this.state.pageCount > 0 ? (
            <div className="paginate">
              <h2>Total de registros: {this.state.length}</h2>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Child;


