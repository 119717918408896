import React, {useState, useEffect} from 'react'
import './index.css'
import { useNavigate } from 'react-router';
import { MdEmail, MdLock } from "react-icons/md"
import { HiEye, HiEyeOff } from "react-icons/hi"
import { FaBuilding} from "react-icons/fa"
import api from '../../Services/api';
import { AxiosResponse } from 'axios';
import logo from '../../img/now1.png'
import Swal from 'sweetalert2';

function Login() {
   
   const navigate = useNavigate();
   const [company, setCompany] = useState("")
   const [login, setLogin] = useState("")
   const [senha, setSenha] = useState("")
   const [show, setShow] = useState(false)
   const [sucesso, setSucesso] = useState('');  

   const handleClick = (e:any) => {
      e.preventDefault()
      setShow(!show);
   }

   useEffect(() => {
      localStorage.removeItem("PrivateRoute");
   }, []);

   async function ValidarLogin(){
      let ret:AxiosResponse;

      localStorage.setItem("userToken", '');

         try{
            ret = await api.get('/user/login',{params:{
                login: login,
                senha: senha,
                company: company
            }}) 
          
            if (ret.data.status === 200){
               localStorage.removeItem("AMBLOC");
                setSucesso('S');                 
                //sessionStorage.setItem("dados", JSON.stringify(ret.data.data)); 
                localStorage.setItem("PrivateRoute", ret.data.userToken);
               if(ret.data.data[0].use_usertype === 'Administrador'){
                 localStorage.setItem("ambiente", '%'); 
                 localStorage.setItem("login", ret.data.data[0].use_login);
                 localStorage.setItem("User", ret.data.data[0].use_name);
                 localStorage.setItem("email", ret.data.data[0].use_email);
                 localStorage.setItem("usertype", ret.data.data[0].use_usertype);
                 localStorage.setItem("userToken", ret.data.userToken);       
               }else if(ret.data.data[0].use_usertype === 'Padrao'){
                  localStorage.setItem("login", ret.data.data[0].use_login);
                  localStorage.setItem("ambiente", ret.data.data[0].use_company); 
                 localStorage.setItem("User", ret.data.data[0].use_name);
                 localStorage.setItem("email", ret.data.data[0].use_email);
                 localStorage.setItem("usertype", ret.data.data[0].use_usertype);
                 localStorage.setItem("userToken", ret.data.userToken);
               }else if(ret.data.data[0].use_usertype === 'Operador'){
                  localStorage.setItem("login", ret.data.data[0].use_login);
                  localStorage.setItem("ambiente", ret.data.data[0].use_company); 
                  localStorage.setItem("User", ret.data.data[0].use_name); 
                  localStorage.setItem("email", ret.data.data[0].use_email);
                  localStorage.setItem("usertype", ret.data.data[0].use_usertype);
                  localStorage.setItem("userToken", ret.data.userToken);
                }
                navigate('/dashboard');
            } else {
                setSucesso('N');
            }   
        }catch(e: any){
            if(e.response === undefined){   
                setSucesso('E');
            } else {
                setSucesso('N');
                }     
        }  
  }

  function handleInput(e:any) {
   var ss = e.target.selectionStart;
   var se = e.target.selectionEnd;
   e.target.value = e.target.value.toUpperCase();
   e.target.selectionStart = ss;
   e.target.selectionEnd = se;
}

   return (
      <div className="login">  
      
         <div className="login-inf">
         <span className='title-informativo'>Seja bem-vindo ao agendador de tarefas!</span>
         </div>

         <div className="login-right">
         <img className="logo" src={logo}  alt="logo" />

         <div className="login-loginInputAmbiente">
               <FaBuilding />
               <input
                 className='mascInput'
                  placeholder="AMBIENTE"
                  value={company}
                  onChange={e => setCompany(e.target.value)}
               />
            </div>

            <div className="login-loginInputEmail">
               <MdEmail />
               <input
                  placeholder="USUÁRIO"
                  value={login}
                  onChange={e => setLogin(e.target.value)}
               />
            </div>

            <div className="login-loginInputPassword">
               <MdLock />
               <input
                  placeholder="SENHA"
                  type={show ? "text" : "password"}
                  value={senha}
                  onChange={e => setSenha(e.target.value)}
               />
               <div className="login-eye">
                  {show ? (
                     <HiEye
                        size={20}
                        onClick={handleClick}
                     />
                  ) : (
                        <HiEyeOff
                           size={20}
                           onClick={handleClick}
                        />
                     )}
               </div>
            </div>
            <div className='contente-senha'>
            <span className='title-senha'>Esqueceu sua senha?</span>
            </div>
            {sucesso === 'N' ? <div className='alerta'><p>Usuário ou senha inválida.</p></div> : null}
            <button type="submit" onClick={ValidarLogin}>
               Entrar
            </button>

         </div>
      </div>
   )
}

export default Login

